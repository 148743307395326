<template>
    <v-row>
      <v-card-title primary-title>Configurações</v-card-title>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          outlined
          dense
          flat
          height="10px"
          label="Empresa"
          return-object
          item-text="fantasyName"
          loader-height
          v-model="component.selectEnterprise"
          :items="component.enterprises"
          ></v-select>
      </v-col>
      <v-btn
        color="success mr-2 mt-3"
        @click="openDialog()"
        >Adicionar</v-btn
      >
    </v-row>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  export default {
    props: {
      component: Object,
    },
  
    computed: {
      ...mapGetters(["getEnterprises"]),
    },
  
    methods: {
        async getAllEnterprise() {
            const payload = {
                page: null,
                limit: null,
                paginate: false,
            };
            await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
        },

        openDialog() {
          this.component.id = null
          this.component.setRegisterDialog()
        }
    },
  
    watch: {
      getEnterprises(val) {
        this.component.enterprises = Object.values(val);
        this.component.selectEnterprise = this.component.enterprises[0];
        
      },
    },

    created() {
        this.getAllEnterprise()
    }
  };
  </script>
  
  <style></style>
  