<template>
    <v-row>
      <v-card-title primary-title>Centro de Custo</v-card-title>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          outlined
          dense
          return-object
          flat
          height="10px"
          label="Empresa"
          item-value="id"
          item-text="fantasyName"
          loader-height
          v-model="selectEnterprise"
          :items="enterprises"
          ></v-select>
      </v-col>
      <v-btn
        color="success mr-2 mt-3"
        @click="openDialog()"
        >Adicionar</v-btn
      >
    </v-row>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
  export default {
    props: {
      component: Object,
    },
  
    computed: {
      ...mapGetters(["getEnterprises"]),
    },
  
    data: () => ({
        selectEnterprise: Object(),
        enterprises: Array(),
    }),
  
    methods: {
        async getAllEnterprise() {
            const payload = {
                page: null,
                limit: null,
                paginate: false,
            };
            await this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
        },

        openDialog() {
          this.component.id = null
          this.component.setRegisterDialog()
        }
    },
  
    watch: {
      getEnterprises(val) {
        this.enterprises = Object.values(val);
        this.selectEnterprise = this.enterprises[0];
      },
    },

    created() {
        this.getAllEnterprise()
    }
  };
  </script>
  
  <style></style>
  