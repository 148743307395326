let dataTopProductsSales = {
    series: [{
        data: []
    }],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250,
            width: 800,
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        dataLabels: {
            enabled: false,
        },

        legend: {
            position: 'bottom'
        },

        xaxis: {
            categories: [],
            labels: {
                show: false
            },
        },
        title: {
            text: 'Produtos mais vendidos',
            align: 'center',
            floating: true
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    },
}

let mobileDataTopProductsSales = {
    series: [{
        data: []
    }],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250,
            width: 300,
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                distributed: true,
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        dataLabels: {
            enabled: false,
        },
        
        legend: {
            position: 'bottom'
        },
        
        xaxis: {
            categories: [],
            labels: {
                show: false
            },
        },
        title: {
            text: 'Produtos mais vendidos',
            align: 'center',
            floating: true
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    },
}

let modelTopProductsSales = {
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250,
            width: 300,
        },
    },
}

let mobileModelTopProductsSales = {
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250,
            width: 800,
        },
    },
}
export { dataTopProductsSales, modelTopProductsSales, mobileDataTopProductsSales, mobileModelTopProductsSales }