<template>
    <v-row>
        <v-dialog v-model="component.dialogCancel" persistent max-width="400">
            <v-card>
                <v-card-title>Deseja cancelar troca?</v-card-title>
                <v-card-subtitle>A troca selecionada será cancelada.</v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="() => {
                            closeDialog();
                        }
                        ">Cancelar</v-btn>
                    <v-btn color="success" @click="() => {
                            submit();
                        }
                        ">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {CANCEL_EXCHANGE} from "../../../store/actions/exchange_control"
export default {
    props: {
        component: Object,
    },

    data: () => ({
      nfceData: Object(),
    }),

    methods: {
        setData() {
            this.nfceData = this.component.cancelExchangeData
            this.nfceData.sale.cliente.people.name = String()
            if (this.nfceData.sale.cliente.type_people == "PhysicalPeople") {
                this.nfceData.sale.cliente.people.name = this.nfceData.sale.cliente.people.physicalpeople.name
            } else {
                this.nfceData.sale.cliente.people.name = this.nfceData.sale.cliente.people.legalpeople.fantasyName
            }
            this.nfceData.sale.info = String()
            this.nfceData.sale.taxoperationToCancel = this.component.taxOperationToCancel
        },

        async submit() {
            const payload = this.nfceData
            payload.exchange_id = payload.id
            await this.$store.dispatch(CANCEL_EXCHANGE, payload);
            this.component.getAllExchange()
            this.closeDialog()
        },

        closeDialog() {
            this.component.dialogCancel = false
        }
    },

    created() {
        this.setData()
    }
};
</script>

<style></style>