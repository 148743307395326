const GET_ALL_BILLSPAY = "GET_ALL_BILLSPAY"
const UPDATE_BILLSPAY = "UPDATE_BILLSPAY"
const DESTROY_BILLSPAY = "DESTROY_BILLSPAY"
const ADD_BILLSPAY = "ADD_BILLSPAY"
const DOWNLOAD_BILLSPAY = "DOWNLOAD_BILLSPAY";
const FIND_BILLSPAY = "FIND_BILLSPAY"
const AUTO_COMPLETE = "AUTO_COMPLETE"
const GET_BILLS_PAY_BY_STATUS = "GET_BILLS_PAY_BY_STATUS"
const BILLS_PAY_REVERSAL = "BILLS_PAY_REVERSAL"
const AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS = "AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS"
const GET_ALL_BILLS_PAY_BY_DATE = "GET_ALL_BILLS_PAY_BY_DATE"
const DOWNLOAD_BILLSPAY_MULTIPLE = "DOWNLOAD_BILLSPAY_MULTIPLE"
const GET_ALL_BILLSPAY_BY_STATUS = "GET_ALL_BILLSPAY_BY_STATUS"
const AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY = "AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY"
const GET_REPORT_BILLSPAY = "GET_REPORT_BILLSPAY"
// s


module.exports = {
    GET_ALL_BILLSPAY,
    UPDATE_BILLSPAY,
    DESTROY_BILLSPAY,
    ADD_BILLSPAY,
    DOWNLOAD_BILLSPAY,
    FIND_BILLSPAY,
    AUTO_COMPLETE,
    GET_BILLS_PAY_BY_STATUS,
    BILLS_PAY_REVERSAL,
    AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS,
    GET_ALL_BILLS_PAY_BY_DATE,
    DOWNLOAD_BILLSPAY_MULTIPLE,
    GET_ALL_BILLSPAY_BY_STATUS,
    AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY,
    GET_REPORT_BILLSPAY,
}