var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{model:{value:(_vm.component.valuesForm),callback:function ($$v) {_vm.$set(_vm.component, "valuesForm", $$v)},expression:"component.valuesForm"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"*Valor do Titulo","readonly":_vm.component.justVisible,"type":"number","prefix":"R$","outlined":"","rules":[() => !!_vm.component.object.value_title || 'Campo Obrigatorio'],"required":""},model:{value:(_vm.component.object.value_title),callback:function ($$v) {_vm.$set(_vm.component.object, "value_title", $$v)},expression:"component.object.value_title"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Abatimento","readonly":_vm.component.justVisible,"type":"number","prefix":"R$","outlined":"","required":"","rules":[
          () => !!_vm.component.object.dejection || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.dejection &&
              _vm.component.object.dejection.length >= 0) ||
            'Campo tem que ser maior do que 0',
        ]},model:{value:(_vm.component.object.dejection),callback:function ($$v) {_vm.$set(_vm.component.object, "dejection", $$v)},expression:"component.object.dejection"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Juros","readonly":_vm.component.justVisible,"type":"number","prefix":"R$","outlined":"","required":"","rules":[() => !!_vm.component.object.interest || 'Campo Obrigatorio']},model:{value:(_vm.component.object.interest),callback:function ($$v) {_vm.$set(_vm.component.object, "interest", $$v)},expression:"component.object.interest"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Valor Liquido","readonly":_vm.component.justVisible,"prefix":"R$","readonly":"","outlined":""},model:{value:(_vm.component.object.value_net),callback:function ($$v) {_vm.$set(_vm.component.object, "value_net", $$v)},expression:"component.object.value_net"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Saldo Titulo","readonly":_vm.component.justVisible,"prefix":"R$","readonly":"","outlined":""},model:{value:(_vm.component.object.title_balance),callback:function ($$v) {_vm.$set(_vm.component.object, "title_balance", $$v)},expression:"component.object.title_balance"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }