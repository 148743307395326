<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:[`item.actions`]="{ item }">
         <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  information(item);
                }
              "
              >mdi-eye</v-icon
            >
          </template>
          <span>Visualizar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
       class="mb-6"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import DestryDialog from "../destroyDialog/destroyDialog";
export default {
  props: {
    component: Object,
    getters: Object,
    destroy_object: Function,
    headers: Array,
    change_addDialog: Function,
    information: Function,
  },
  components: { DestryDialog },
  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),
  methods: {
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },
  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if(this.component.search) {
        this.component.GetAutocomplete(val)
      } else if(this.component.searchByDate) {
        this.component.dateSearch(val)
      } else {
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
