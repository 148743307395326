import { render, staticRenderFns } from "./Write_off_xml.vue?vue&type=template&id=8b081160&"
import script from "./Write_off_xml.vue?vue&type=script&lang=js&"
export * from "./Write_off_xml.vue?vue&type=script&lang=js&"
import style0 from "./Write_off_xml.vue?vue&type=style&index=0&id=8b081160&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports