<template>
  <div>
    <v-data-table
      :headers="atual.header"
      :items="atual.getter"
      hide-default-footer
      no-data-text="Nenhum Cliente Cadastrado"
      no-results-text="Nenhum Cliente Encontrado"
    >
      <template v-slot:[`item.actions`]="{ item, index }">
        <v-icon
          color="green darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              visibleItem(item);
            }
          "
          >mdi-eye</v-icon
        >
        <v-icon
          v-if="component.permission.Editar"
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item, index);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          v-if="component.permission.Excluir"
          @click="
            () => {
              deleteItem(item);
            }
          "
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <Edit :component="this" ref="edit" />
    <Delete :component="this" />

    <div>
      <v-divider class="mt-6"></v-divider>
      <v-pagination
        v-model="atualCurrent"
        :length="atualTotal"
        color="success"
        :total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { headers } from "./script";
import Edit from "./Edit_Item/";
import Delete from "./Delete_Item/";
import {
  GET_ALL_CLIENT_BY_ENTERPRISE,
  DESTROY_CLIENT,
  ITEM_TO_UPDATE_CLIENT,
  GET_AUTOCOMPLETE_CLIENT,
} from "../../../store/actions/client";
import { mapGetters } from "vuex";
import { insertCnpjMask } from "../../Utils/global_functions/clean_cnpj_cpf";

export default {
  props: { component: Object() },
  components: { Edit, Delete },
  data: () => ({
    current: 1,
    dialog: false,
    dialog_cancel: false,
    ObjectToDestroy: Object(),
    headers,

    legalPeople: Object(),
    physicalPeople: Object(),

    //Pagination Pessoa Física
    pagPhysicalTotal: Number(),
    pagPhysicalCurrent: Number(),

    //Pagination Pessoa Jurídica
    pagLegalTotal: Number(),
    pagLegalCurrent: Number(),

    //Pagination
    atualTotal: Number(),
    atualCurrent: Number(),

    atual: {
      header: Array(),
      getter: Array(),
    },
  }),

  computed: {
    ...mapGetters([
      "getPhysicalClients",
      "getLegalClients",
      "getItemUpdatedClient",
      "getItemToDelete",
      "getAddedClients",
      "getClientPhysicalAuto",
      "getClientLegalAuto",
    ]),
  },
  methods: {
    edit_item(item, index) {

      let payload = {
        ...item,
        index,
        header: "Atualizar",
        isUpdate: true,
      };
      this.$store.dispatch(ITEM_TO_UPDATE_CLIENT, payload);
      this.$store.dispatch("setShowDialogRegisterClient", true);
      item;
    },
    visibleItem(item) {
      this.$store.dispatch(ITEM_TO_UPDATE_CLIENT, {
        item: item,
        header: "Visualizar",
        justVisible: true,
      });
      this.$store.dispatch("setShowDialogRegisterClient", true);
    },
    deleteItem(item) {
      this.dialog_cancel = !this.dialog_cancel;
      this.ObjectToDestroy = { ...item };
      this.ObjectToDestroy.enterprise_id = this.component.enterpriseId
    },
    async confirmDelete() {
      try {
        await this.$store.dispatch(DESTROY_CLIENT, this.ObjectToDestroy);
      } catch (e) {
        alert(e);
      } finally {
        this.dialog_cancel = !this.dialog_cancel;
      }
    },

    //Controle de trocas
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];

        if(isLegal) {
          this.atual.getter = this.getLegalClients
          this.atualTotal = this.pagLegalTotal
          this.atualCurrent = this.pagLegalCurrent
        } else {
          this.atual.getter = this.getPhysicalClients
          this.atualTotal = this.pagPhysicalTotal
          this.atualCurrent = this.pagPhysicalCurrent
        }
    },

    //Atualização Pagination
    PagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },

    // GetAll pessoa Física e Juŕidica
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT_BY_ENTERPRISE, {
        type_people: "PhysicalPeople",
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.component.enterpriseId,
      });
    },
    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT_BY_ENTERPRISE, {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.component.enterpriseId,
      });
    },

    async AutocompleteClientPhysical(page = 1) {
      if (this.component.search == "") {
        this.GetAllPhysical();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "PhysicalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.searchInput,
          page: page,
          limit: 10,
          paginate: true,
        });
      }
    },

    async AutocompleteClientLegal(page = 1) {
      if (this.component.search == "") {
        this.GetAllLegal();
      } else {
        await this.$store.dispatch(GET_AUTOCOMPLETE_CLIENT, {
          type_people: "LegalPeople",
          enterprise_id: this.component.enterpriseId,
          search: this.component.searchInput,
          page: page,
          limit: 10,
          paginate: true,
        });
      }
    },
  },


  watch: {
    getPhysicalClients: function (val) {
      if (val.length == 0) {
        this.pagPhysicalCurrent = 1;
        this.pagPhysicalTotal = 1
        if(!this.component.isFormalPeople){ 
          this.PagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
          this.atual.getter = val
        }
      } else {
        this.pagPhysicalCurrent = val[0].page.current_page;
        this.pagPhysicalTotal = val[0].page.last_page;
        if(!this.component.isFormalPeople){ 
          this.PagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
          this.atual.getter = val
        }
      }
    },
    getLegalClients: function (val) {
      if(val.length == 0) {
        this.pagLegalCurrent = 1;
        this.pagLegalTotal = 1
        if(this.component.isFormalPeople) {
          this.PagPhysical(this.pagLegalTotal, this.pagLegalCurrent);
          this.atual.getter = val
        }
      } else {
        this.pagLegalTotal = val[0].page.last_page;
        this.pagLegalCurrent = val[0].page.current_page;
        if(this.component.isFormalPeople) {   
          insertCnpjMask(val);
          this.atual.getter = val
          this.PagPhysical(this.pagLegalTotal, this.pagLegalCurrent);
        }
      }
    },
    getItemToDelete: function (val) {
      //Novo algoritmo de excluir um elemento de uma lista

      let index = this.atual.getter
        .map((element) => element.id)
        .indexOf(val.id);
      this.atual.getter.splice(index, 1);
    },
    getAddedClients: function (val) {
      if (val.type_people == "LegalPeople") {
        this.atual.getter.push(val);
      }
    },
    atualCurrent: function (val) {
      if(this.component.search.length >= 2 && this.component.isFormalPeople) {
        this.AutocompleteClientLegal && this.AutocompleteClientLegal(val)
      }
      else if(this.component.search.length >= 2 && !this.component.isFormalPeople) {
        this.AutocompleteClientPhysical && this.AutocompleteClientPhysical(val)
      }
      else if(this.component.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },

    "component.search": function () {
      this.AutocompleteClientPhysical();
      this.AutocompleteClientLegal();
    },

    "component.searchInput": function (val) {
      if(val == "") {
        this.component.search = ""
      }
    },

    // getClientPhysicalAuto: function (val) {
    //   if (val[0].data == []) {
    //     this.PagPhysical(1, 1);
    //     this.atual.getter = val
    //   } else {
    //     this.physicalPeople = val
    //     if(!this.component.isFormalPeople) {
    //       this.pagPhysicalTotal = val[0].page.last_page;
    //       this.pagPhysicalCurrent = val[0].page.current_page;
    //       this.PagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
    //       this.atual.getter = val
    //     }
    //   }
    // },

    // getClientLegalAuto: function (val) {
    //   if (val[0].page) {
    //     insertCnpjMask(val);
    //     this.legalPeople = val

    //     if(this.component.isFormalPeople) {
    //       this.atual.getter = val
    //       this.pagLegalTotal = val[0].page.last_page;
    //       this.pagLegalCurrent = val[0].page.current_page;
    //       this.PagPhysical(this.pagLegalTotal, this.pagLegalCurrent);
    //     }
    //   }
    // },

    "component.enterpriseId": function (val) {
        this.GetAllLegal && this.GetAllLegal();
        this.GetAllPhysical && this.GetAllPhysical();
    },
  },

  mounted() {
    this.isLegal();

    this.$watch(
      () => this.component.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>
