<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteAccountPlan()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getAccount_Plan"
        :destroy_object="destroy_object"
        :search="search"
        :headers="headers"
        :change_addDialog="change_addDialog"
      />
      <ModalAccountplans :component="this" v-if="getShowDialogRegisterAccountPlan"/>
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>

<script>
import ModalAccountplans from "../components/account_plans/AddDialog/index";
import Container from "../components/Utils/Container/Container";
import Table from "../components/account_plans/Table/Index";
import CardTitle from "../components/account_plans/CardTitle/index";
import loadScreen from "../components/load/";

import {
  GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE,
  DESTROY_ACCOUNT_PLAN,
  GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE,
} from "../store/actions/account_plan";
import { mapGetters } from "vuex";
import AlertApi from "./../components/alert_api/alert_api";

export default {
  data: () => ({
    showDialog: Boolean(),
    permission: Object(),
    search: "",
    searchInput: "",
    getter: "getAccount_Plan",
    addDialog: false,
    filter: String(),
    objectToUpdate: {},
    title: "Plano de Contas",
    enterpriseId: Number(),
    headers: [
      { text: "Status", value: "status" },
      { text: "Descrição", value: "description" },
      { text: "Código Contabil", value: "accountCode" },
      { text: "Gera Movimentação", value: "generateMovement" },
      { text: "Tipo Contabil", value: "accountType" },
      { text: "Plano Contabil", value: "planType" },
      { text: "Ações", value: "actions" },
    ],
  }),
  components: {
    ModalAccountplans,
    Container,
    loadScreen,
    CardTitle,
    Table,
    AlertApi,
  },
  computed: {
    ...mapGetters([
      "getAccount_Plan",
      "getCadastrosLinks",
      "getShowDialogRegisterAccountPlan",
      
    ]),
  },
  methods: {
    async destroy_object(item) {
      const car_adm = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_ACCOUNT_PLAN, car_adm);
      } catch (error) {
        alert(error);
      }
    },
    async GetAll(page = 1) {
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN_BY_ENTERPRISE, {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId
      });
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    async accountplan_filter(page = 1) {
        let value = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
          enterprise_id: this.enterpriseId
        };
        await this.$store.dispatch(GET_FILTER_ACCOUNT_PLAN_BY_ENTERPRISE, value);
    },
    autocompleteAccountPlan() {
      this.search = this.searchInput
    },
  },
  watch: {
    searchInput: function (val) {
      if(val.length == 0) {
        this.search = ""
        this.GetAll();
      }
    },
    search: function (val) {
      if(val.length >= 2){
        this.accountplan_filter()
      }
    },

    enterpriseId: function(val) {
      this.GetAll()
    }
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
  },
};
</script>
