<template>
  <div>
    <v-form v-model="component.generateDataForm">
      <v-col>
        <v-select label="Status" disabled :items="status" v-model="component.object.status" outlined></v-select>
      </v-col>
      <v-col>
        <v-text-field label="*Data Emissao" :readonly="component.justVisible" v-model="component.object.issuance_date" type="date" required outlined :rules="[
          () => !!component.object.issuance_date || 'Campo Obrigatorio',
          () =>
            (!!component.object.issuance_date &&
              component.object.issuance_date.length >= 2) ||
            'Campo tem que ser maior do que 2',
        ]"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field label="*Data Vencimento" :readonly="component.justVisible" v-model="component.object.issuance_due" type="date" required outlined :rules="[
          () => !!component.object.issuance_due || 'Campo Obrigatorio',
          () =>
            (!!component.object.issuance_due &&
              component.object.issuance_due.length >= 0) ||
            'Campo tem que ser maior do que 0',
        ]"></v-text-field>
      </v-col>
      <v-col>
        <v-select label="*Tipo do Titulo" :readonly="component.justVisible" v-model="component.object.type_title" :items="title" outlined :rules="[
          () => !!component.object.type_title || 'Campo Obrigatorio',
          () =>
            (!!component.object.type_title &&
              component.object.type_title.length >= 2) ||
            'Campo tem que ser maior do que 2',
        ]"></v-select>
      </v-col>
      <v-col>
        <v-text-field label="*Numero do Titulo" :readonly="component.justVisible" required v-model="component.object.number_title" outlined maxlength="15"
          counter="15" :rules="[
            () => !!component.object.number_title || 'Campo Obrigatorio',
            () =>
              (!!component.object.number_title &&
                component.object.number_title.length >= 0) ||
              'Campo tem que ser maior do que 0',
          ]"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field label="*Fornecedor" readonly aria-readonly="true" :append-outer-icon="icons[0]"
          @click:append-outer="showDialogAction" append-icon="mdi-magnify" @click:append="dialog = !dialog"
          v-model="component.object.peopleComputed.people_name" outlined required :rules="[
            () =>
              !!component.object.peopleComputed.people_name ||
              'Campo Obrigatorio',
            () =>
              (!!component.object.peopleComputed.people_name &&
                component.object.peopleComputed.people_name.length >= 1) ||
              'Campo tem que ser maior do que 1',
          ]"></v-text-field>
      </v-col>
    </v-form>
    <v-dialog max-width="800" persistent v-model="dialog">
      <v-card>
        <v-container>
          <v-card-title>Fornecedores</v-card-title>
          <v-switch :readonly="component.justVisible" v-model="isFormalPeople" label="Pessoa Jurídica" color="primary">
          </v-switch>
          <v-text-field :readonly="component.justVisible" @keyup.enter="setAutocompleteProvider()" outlined label="Pesquisar" append-icon="mdi-magnify" v-model="searchInput"></v-text-field>
          <v-divider></v-divider>
          <v-data-table :headers="atual.header" :items="atual.getter" hide-default-footer
            @click:row="rowClicked" no-data-text="Sem registros"></v-data-table>
            <v-divider></v-divider>
            <v-pagination class="mb-6" v-model="atualCurrent" :length="atualTotal" :total-visible="10" color="success"></v-pagination>
            <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="success" @click="dialog = !dialog">Fechar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <RegisterProviderDialog :out_of_register="true" :showDialog="showDialog" />
  </div>
</template>

<script>
import { headers } from "./script";
import { mapGetters } from "vuex";
import { GET_ALL_PROVIDER, GET_FILTER_PROVIDER } from "../../../../store/actions/provider";
import { mdiPlusCircle } from "@mdi/js";
import RegisterProviderDialog from "../../../provider/AddDialog/index.vue";

export default {
  components: {
    RegisterProviderDialog,
  },
  props: {
    component: Object,
  },

  computed: {
    ...mapGetters([
      "getPhisycalProvider",
      "getLegalProvider",
      "getShowDialogRegisterProvider",      
      "getNewProviderToAdd",
      // "getItemAdded"
    ]),
  },

  data() {
    return {
      showDialog: false,
      icons: [mdiPlusCircle],
      status: ["Aberto", "Baixado", "Renegociado", "Cancelado"],
      isFormalPeople: false,
      title: ["Boleto", "Depósito Bancário", "Dinheiro"],
      dialog: false,
      headers,
      getter: Array,
      search: "",
      searchInput: "",
      atual: {
        header: Array(),
        getter: Array(),
      },
      pagPhysicalTotal: Number(),
      pagPhysicalCurrent: Number(),

      pagLegalTotal: Number(),
      pagLegalCurrent: Number(),

      atualCurrent: Number(),
      atualTotal: Number(),
    };
  },

  methods: {
    showDialogAction() {
      this.$store.dispatch("setShowDialogRegisterProvider", true);
    },

    rowClicked(row) {
      if (row.type_people == "PhysicalPeople") {
        this.component.object.peopleComputed.people_name = row.physicalpeople.name;
      } else {
        this.component.object.peopleComputed.people_name = row.legalpeople.fantasyName;
      }
      this.component.object.peopleComputed.people_id = row.id;
      this.component.object.peopleComputed.type_people = row.type_people;
      if(!row.observer){
        this.dialog = false;
        }
      },

    pagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },
    pagLegal(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },

    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];

      this.atual.getter = isLegal
        ? this.getLegalProvider
        : this.getPhisycalProvider;

      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },

    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        type_people: "PhysicalPeople",
        page,
        limit: 10,
        paginate: true,
      });
    },

    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_PROVIDER, {
        type_people: "LegalPeople",
        page,
        limit: 10,
        paginate: true,
      });
    },
    
    setAutocompleteProvider() {
      this.search = this.searchInput
    },

    getAutocompleteProviderPhysical(page = 1) {
      const payload = {
          page: page,
          limit: 10,
          paginate: true,
          type_people: "PhysicalPeople",
          search: this.search,
          enterprise_id: this.component.enterpriseId,
      }
      this.$store.dispatch(GET_FILTER_PROVIDER, payload)
    },

    getAutocompleteProviderLegal(page = 1) {
      const payload = {
          page: page,
          limit: 10,
          paginate: true,
          type_people: "LegalPeople",
          search: this.search,
          enterprise_id: this.component.enterpriseId,
      }
      this.$store.dispatch(GET_FILTER_PROVIDER, payload)
    },
  },

  created() {
    this.GetAllPhysical();
    this.GetAllLegal();
  },

  watch: {
    getShowDialogRegisterProvider: function () {
      if (this.getShowDialogRegisterProvider) {
        this.showDialog = true;
      } else {
        this.showDialog = false;
      }
    },

    getPhisycalProvider: function (val) {
      this.pagPhysicalTotal = val[0].page.last_page;
      this.pagPhysicalCurrent = val[0].page.current_page;
      this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
    },

    getNewProviderToAdd:function(val){
      val.observer = true
      this.rowClicked(val)
    }, 

    getLegalProvider: function (val) {
      this.pagLegalTotal = val[0].page.last_page;
      this.pagLegalCurrent = val[0].page.current_page;
      this.pagLegal(this.pagLegalTotal, this.pagLegalCurrent);
    },

    atualCurrent: function (val) {
      if(this.search.length >= 2) {
          if (this.isFormalPeople) {
            this.getAutocompleteProviderLegal(val)
          } else {
            this.getAutocompleteProviderPhysical(val)
        }
      } else {
        if (this.isFormalPeople) {
          this.GetAllLegal && this.GetAllLegal(val);
        } else {
          this.GetAllPhysical && this.GetAllPhysical(val);
        }
      }
    },

    search: function(val) {
      if(val.length >= 2) {
          this.getAutocompleteProviderLegal()
          this.getAutocompleteProviderPhysical()
      }
    },
    searchInput: function(val) {
      if(val.length == 0) {
        this.GetAllPhysical();
        this.GetAllLegal();
        this.search = ""
      }
    },
  },

  mounted() {
    this.isLegal();

    this.$watch(
      () => this.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>

<style>
</style>