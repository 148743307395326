const ADD_SETTINGS = "ADD_SETTINGS";
const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
const AUTOCOMPLETE_SETTINGS = "AUTOCOMPLETE_SETTINGS";
const UPDATE_SETTINGS = "UPDATE_SETTINGS";
const DELETE_SETTINGS = "DELETE_SETTINGS";

module.exports = {
    ADD_SETTINGS,
    GET_ALL_SETTINGS,
    AUTOCOMPLETE_SETTINGS,
    UPDATE_SETTINGS,
    DELETE_SETTINGS,
}
