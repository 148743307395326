<template>
  <v-row justify="center">
    <v-dialog
      persistent
      hide-overlay
      v-model="dialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn icon dark @click="changeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }} Faturamento</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-divider></v-divider>
          <v-tabs class="success">
            <v-tab>
              <v-icon class="mr-2"> mdi-file-document </v-icon> Dados Gerais
            </v-tab>
            <v-tab>
              <v-icon class="mr-2"> mdi-file-find</v-icon>
              Itens da Nota
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">mdi-truck</v-icon>
              Transportadora
            </v-tab>
            <v-tab>
              <v-icon class="mr-2"> mdi-information</v-icon>
              Informações da nota
            </v-tab>
            <v-tab-item>
              <GeneralDate
                :component="this"
                :client="client"
                :isDisable="disabled"
              />
            </v-tab-item>
            <v-tab-item>
              <RevenuesItems :component="this" :isDisable="disabled" />
            </v-tab-item>
            <v-tab-item>
              <Transport :component="this" :isDisable="disabled" />
            </v-tab-item>
            <v-tab-item>
              <InformationRevenue
                :eager="true"
                :component="this"
                :isDisable="disabled"
              />
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions v-show="!disabled">
            <v-spacer></v-spacer>
            <v-btn class="error" @click="reset()">
              <v-icon class="mr-1">mdi-cancel</v-icon> Limpar Formulário</v-btn
            >
            <v-btn class="success" :disabled="!valid" @click="save()">
              <v-icon class="mr-1">mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <AlertError
      v-if="dialog_alert"
      :component="this"
      :title_error="tilte_error"
      :listErrors="subtitle_error"
    />
  </v-row>
</template>

<script>
import GeneralDate from "../Tabs/TabGenerateDate/";
import InformationRevenue from "../Tabs/TabInfoNote/";
import RevenuesItems from "../Tabs/TabNoteItens/";
import Transport from "../Tabs/TabTransport/";
import AlertError from "../AddDialog/Modal_Error/";
import businessRole from "../business_roles/";
import { ADD_PRENOTE, UPDATE_REVENUES } from "../../../store/actions/revenues";
import { DESTROY_REVENUES_ITENS } from "../../../store/actions/revenuesitens";
import { mapGetters } from "vuex";

export default {
  components: {
    GeneralDate,
    InformationRevenue,
    RevenuesItems,
    Transport,
    AlertError,
  },
  props: {
    component: Object,
    title: String,
    disabled: Boolean,
    dialog: Boolean,
    changeDialog: Function,
    enterpriseId: Number,
    enterpriseType: Number,
    object: Object,
  },
  data: () => ({
    valid: Boolean(),
    validCST: Boolean(),
    preDialog: Boolean(),
    confirmDialog: Boolean(),
    totalNF: Number(),
    client: Object(),
    dialog_alert: Boolean(),
    tilte_error: String(),
    subtitle_error: String(),

    form: {
      status: "Pre-pedido",
      number_grade: "Não Emitida",
      finality_nf: String(),
      date_of_issue: String(),
      access_key: "Não Emitida",
      authorization_protocol: "Não Emitida",
      series: "Não Emitida",
      type_grade_id: Number(), //Enum no back
      value_total_products: Number(),
      value_book: Number(),
      value_total_note: Number(),
      value_discount: Number(),
      value_icms_substituicao_total: Number(),
      value_freigth: Number(),
      base_calc_icms_total: Number(),
      base_calc_susbt_total: Number(),
      value_icms_free_total: Number(),
      value_account_total: Number(),
      value_outher_tax_total: Number(),
      value_icms_total: Number(),
      note: String(),
      value_security: Number(),
      value_ipi_total: Number(),
      value_ipi_outher_total: Number(),
      value_ipi_free_total: Number(),
      base_calc_ipi_total: Number(),
      value_icms_outher_product: Number(),
      value_other_incidental_expenses: Number(), // Verificar
      vehicle_plate: String(),
      volume_transported: String(),
      gross_weight: String(),
      net_weight: String(),
      volume_type: String(),
      receiptCode: "1",
      value_base_pis_total: Number(),
      value_pis_total: Number(),
      value_base_confis_total: Number(),
      value_confis_total: Number(),
      value_base_pis_st_total: Number(),
      value_pis_st_total: Number(),
      value_base_cofins_st_total: Number(),
      value_cofins_st_total: Number(),
      value_other_pis_total: Number(),
      transporter_id: null,
      client_id: Number(),
      payment_terms_id: null,
      tax_operation_types_id: Number(),
      account_plans_id: null,
      enterprise_id: Number(),

      revenusItem: Array(),
    },

    validGenerateDate: Boolean(),

  }),
  computed: {
    ...mapGetters(["getRevenusItens"]),
  },

  methods: {
    ...businessRole,
    //Resetar os campos
    reset() {
      let object = {};

      for (const key in this.form) {
        let element = this.form[key];
        let type = typeof element;

        switch (type) {
          case "number":
            object[key] = Number();
            break;
          case "string":
            object[key] = String();
            break;
          case "object":
            object[key] = Array();
            break;

          default:
            object[key] = String();
            break;
        }
      }
      this.form = object;
      this.form = object;
      this.form.status = "Pre-nota";
      this.form.number_grade = "Não Emitida";
      this.form.access_key = "Não Emitida";
      this.form.authorization_protocol = "Não Emitida";
      this.form.series = "Não Emitida";
      this.clear_revenues();
    },

    //Limpar o RevenuesItem
    clear_revenues() {
      try {
        this.$store.dispatch(DESTROY_REVENUES_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },

    //Atualizar os autocompletes
    refresh_dates() {
      if (this.enterpriseType === 3) {
        this.validCST = true;
      } else {
        this.validCST = false;
      }
    },
    createSequenceItem(itens) {
      let code = 0;
      let itenscode = [];
      let product = {};

      itens.forEach((e) => {
        product.code_item = 0;
        code += 1;

        product.code_cfop = String(e.cfop_code);
        product.description_product = e.description_product;
        product.description_unit = e.description_unit;
        product.value_book = e.value_book;
        product.value_sale = e.value_sale;

        product.product_id = e.id;
        product.code_item = code;
        product.amount = e.amount;
        product.value_sale = e.venda_sale;
        product.value_subtotal_product = e.value_products;
        product.value_base_icms_product = e.base_icms_product;
        product.aliquot_ipi_product = e.aliquot_ipi_product;
        product.aliquot_icms_product = e.aliquot_icms_product;
        product.value_accounting_product = e.value_accounting_product;
        product.value_base_ipi_product = e.value_base_ipi_product;
        product.value_base_icms_st_product = e.value_base_icms_st_product;
        product.value_icms_st_product = e.value_icms_st_product;
        product.value_descont_product = e.value_descont_product;
        product.value_ipi_product = e.value_ipi_product;
        product.value_icms_product = e.value_icms_product;
        product.value_free_icms_product = e.value_free_icms_product;
        product.value_icms_outher_product = e.value_icms_outher_product;
        product.value_ipi_free_product = e.value_ipi_free_product;
        product.value_ipi_outher_product = e.value_ipi_outher_product;
        product.aliquot_pis = e.aliquot_pis;
        product.aliquot_cofis = e.aliquot_cofis;
        product.value_base_pis_product = e.value_base_pis_product;
        product.value_pis_product = e.value_pis_product;
        product.value_base_confis_product = e.value_base_confis_product;
        product.value_confis_product = e.value_confis_product;
        product.value_base_pis_st_product = e.value_base_pis_st_product;
        product.value_pis_st_product = e.value_pis_st_product;
        product.value_base_cofins_st_product = e.value_base_cofins_st_product;
        product.value_cofins_st_product = e.value_cofins_st_product;
        product.value_other_pis_product = e.value_other_pis_product;
        product.value_other_confis_product = e.value_other_confis_product;
        product.value_free_confis_product = e.value_free_confis_product;
        product.value_free_pis_product = e.value_free_pis_product;
        product.cst_pis_id = e.cst_pis_id;
        product.cst_confis_id = e.cst_confis_id;

        product.cfop_id = e.cfop_id;
        product.cst_id = e.cst_id;
        product.csosn_id = e.csosn_id;
        product.product_id = e.product_id;

        itenscode.push({ ...product });
      });
      return itenscode;
    },

    //Finalizar e Enviar
    async submit() {
      this.form.enterprise_id = this.enterpriseId;
      let note = { ...this.form };
      let itens = this.getRevenusItens;
      note.revenusItem = this.createSequenceItem(itens);

      await this.$store
        .dispatch(ADD_PRENOTE, note)
        .then(() => {
          this.preDialog = !this.preDialog;
          this.changeDialog();
        })
        .catch((reject) => {
          this.tilte_error = reject;
          this.subtitle_error =
            "Verifique os campos obrigatórios e tente novamente";
          this.dialog_alert = !this.dialog_alert;
        });
    },

    //Editar e Enviar
    async submitEdit() {
      let revenue = { ...this.form };
      let itens = this.getRevenusItens;
      revenue.revenusItem = this.createSequenceItem(itens);

      delete revenue.cliente;
      try {
        await this.$store.dispatch(UPDATE_REVENUES, revenue);
      } catch (error) {
        alert(error);
      } finally {
        this.changeDialog();
      }
    },
    //Controle Submit/Editar
    save() {
      if (this.form.id !== undefined) {
        this.submitEdit();
      } else {
        this.submit();
      }
    },
    //Lógica do cabeçalho de nota

    //Data atual
    current_Date() {
      var dNow = new Date();
      var month = dNow.getMonth() + 1;
      var dec, dFull;

      if (month < 10) {
        month = "0" + month;
      }

      if (dNow.getDate() <= 9) {
        dec = "0";

        dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
        this.form.date_of_issue = dFull;
      } else {
        dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
        this.form.date_of_issue = dFull;
      }
    },
    //Atualiza o Objeto Form
    cloneObject() {
      if (this.object !== undefined) {
        this.form = { ...this.object };
      }
    },
  },
  watch: {
    form: {
      handler: function (val) {
        if (val.cliente !== undefined) {
          if (val.cliente.people.physicalpeople !== null) {
            this.client.name = val.cliente.people.physicalpeople.name;
          } else {
            this.client.name = val.cliente.people.legalpeople.fantasyName;
          }
          this.client.id = val.cliente.id;
        }
      },
      deep: true,
    },
    getRevenusItens: function (val) {
      this.baseCalcIcms(val);
      this.calculate_InfoNote(val);
      this.value_products(val);
      this.valid = this.validGenerateDate && (val.length > 0)
      
    },

    validGenerateDate: function(val) {
      this.valid = val && (this.getRevenusItens.length > 0)
    },
  },

  created() {
    this.cloneObject();
    this.current_Date();
  },
  mounted() {
    this.refresh_dates();
  },
  destroyed() {
    this.clear_revenues();
  },
};
</script>

