<template>
    <v-row justify="center">
      <v-dialog
        persistent
        v-model="component.registerDialog"
        max-width="600"
      >
        <v-card>
          <v-card-title primary-title>Configurações</v-card-title>

          <v-container>
            <v-divider></v-divider>
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              class="mt-6"
            >
            <v-row>
              <v-col>
                <v-select
                label="*Empresa"
                color="success"
                v-model="form.enterprise_id"
                :rules="[() => !!form.enterprise_id || 'Campo Obrigatório']"
                item-text="fantasyName"
                item-value="id"
                outlined
                :readonly="component.justView"
                required
                :items="component.enterprises"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                label="*Chave da Configuração"
                color="success"
                v-model="form.key_config"
                :rules="[() => !!form.key_config || 'Campo Obrigatório']"
                outlined
                :readonly="component.justView"
                required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                label="*Valor da Configuração"
                color="success"
                v-model="form.value_config"
                :rules="[() => !!form.value_config || 'Campo Obrigatório']"
                outlined
                :readonly="component.justView"
                required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                label="*Descrição"
                color="success"
                v-model="form.description_settings"
                :rules="[() => !!form.description_settings || 'Campo Obrigatório']"
                outlined
                :readonly="component.justView"
                required
                ></v-text-field>
              </v-col>
            </v-row>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" @click="closeDialog()">
                  <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar</v-btn>
                <v-btn
                  v-if="!component.justView"
                  color="success"
                  class="mr-4"
                  :disabled="!valid"
                  @click="
                    () => {
                      submit();
                    }
                  "
                >
                  <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      component: Object,
    },
    data() {
      return {
        valid: true,
        form: {
            enterprise_id: Number(),
            key_config: String(),
            value_config: String(),
            description_settings: String()
        }
      };
    },
  
    methods: {
        submit() {
          if(this.component.id == null) {
            this.component.create(this.form)
            this.$refs.form.reset();
          } else {
            this.component.update(this.form)
            this.$refs.form.reset();
          }
        },

        closeDialog() {
          this.$refs.form.reset();
          this.component.setRegisterDialog()
        }
      },

      created() {
        if(this.component.id != null) {
          this.form = this.component.settingToUpdate
        }
      },

      beforeDestroy() {
        this.component.justView = false
      }
  
  };
  </script>
  