<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-card color="#4EA03E" dark max width="100%" class="text-center">
            <v-card-text class="text-h5" style="color: white">
              Valor de Vendas
            </v-card-text>
            <v-card-text>
              <v-col class="text-h5" style="color: white">
                R${{ component.value_total_sales }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card color="#F0712C" dark max width="100%" class="text-center">
            <v-card-text class="text-h5" style="color: white">
              Valor de Desconto
            </v-card-text>
            <v-card-text>
              <v-col class="text-h5" style="color: white">
                R${{ component.value_discount }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card color="#FEB019" dark max width="100%" class="text-center">
            <v-card-text class="text-h5" style="color: white">
              Ticket Médio
            </v-card-text>
            <v-card-text>
              <v-col class="text-h5" style="color: white">
                R${{ component.average_ticket }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3">
          <v-card color="#5488BF" dark max width="100%" class="text-center">
            <v-card-text class="text-h5" style="color: white">
              Valor de Trocas
            </v-card-text>
            <v-card-text>
              <v-col class="text-h5" style="color: white">
                R${{ component.value_exchange }}
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="bar" :series="modelSalesPerStore.series" :options="modelSalesPerStore.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="pie" :series="modelValuesSalesWithFormOfPaymentsModel.series"
              :options="modelValuesSalesWithFormOfPaymentsModel.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="pie" :series="modelSalesPerSellers.series" :options="modelSalesPerSellers.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-none d-md-flex">
        <v-col cols="12">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="bar" :series="modelTopProductsSales.series" :options="modelTopProductsSales.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-md-none">
        <v-col cols="12">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="bar" :series="mobileModelTopProductsSales.series"
              :options="mobileModelTopProductsSales.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-none d-md-flex">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="bar" :series="modelMoneyItens.series" :options="modelMoneyItens.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-md-none">
          <v-card style="width: 100%; height: 300px; display: flex; justify-content: center; align-items: center">
            <apexcharch type="bar" :series="mobileModelMoneyItens.series" :options="mobileModelMoneyItens.chartOptions">
            </apexcharch>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import VueApexCharts from "vue-apexcharts";

import { modelSalesPerStore, dataSalesPerStore } from "./ModelDataChart/dataChartStore"
import { dataSalesPerSellers, modelSalesPerSellers } from "./ModelDataChart/dunutsDataChartSallesPerSellers"
import { dataTopProductsSales, modelTopProductsSales, mobileDataTopProductsSales, mobileModelTopProductsSales } from "./ModelDataChart/dataTopProducsSales"
import { dataMoneyItens, modelMoneyItens, mobileDataMoneyItens, mobileModelMoneyItens } from "./ModelDataChart/dataMoneyItens"
import { dataValuesSalesWithFormOfPayments, modelValuesSalesWithFormOfPaymentsModel } from "./ModelDataChart/groupedBarvaluesSalesWithFormOfPayments"

import { DATA_SALES } from "../../../store/actions/dashboard_charts";

import { mapGetters } from "vuex";
import { todayDate } from "../../Utils/global_functions/today_date";
export default {
  components: { apexcharch: VueApexCharts },
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters(["getSelectedEnterprise", "getReportChartSales"]),
  },
  data: () => ({
    indicators: Array(),

    modelSalesPerStore,
    dataSalesPerStore,

    modelSalesPerSellers,
    dataSalesPerSellers,

    dataTopProductsSales,
    modelTopProductsSales,
    mobileDataTopProductsSales,
    mobileModelTopProductsSales,

    dataMoneyItens,
    modelMoneyItens,
    mobileDataMoneyItens,
    mobileModelMoneyItens,

    dataValuesSalesWithFormOfPayments,
    modelValuesSalesWithFormOfPaymentsModel,

    formOfPaymentsLabels: Array(),
    salesPerSellersLabels: Array(),
    dataSalesPerStoreLabels: Array(),

  }),
  methods: {
    async getDataSales(enterprise_id) {
      const payload = {
        initial_date_sales: todayDate(),
        final_date_sales: todayDate(),
        enterprise_id: enterprise_id,
      };
      await this.$store.dispatch(DATA_SALES, payload).then((response) => {
        this.setDataSales(response);
      });
    },

    setDataSales(response) {
      this.setDataValueTotalSales(response);
      this.setDataValuesSalesWithFormOfPayments(response);
      this.setDataSalesPerSellers(response);
      this.setTopSellingProducts(response);
      this.setMoneyItens(response);
    },

    setDataValueTotalSales(response) {
      this.dataSalesPerStore.series = []
      this.dataSalesPerStore.chartOptions.xaxis.categories = []
      this.dataSalesPerStoreLabels = []
      let series = []
      for (let i = 0; i < response[0].valuesTotalSales.length; i++) {
        series.push(response[0].valuesTotalSales[i].venda_total.toFixed(2))
        this.dataSalesPerStoreLabels.push(response[0].valuesTotalSales[i].enterprise_name)
      }
      this.dataSalesPerStore = {
        series: [{
          data: series
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250
          },
          plotOptions: {
            bar: {
              barHeight: '45%',
              distributed: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: this.dataSalesPerStoreLabels,
            labels: {
              show: false
            },
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
            text: 'Valor Total de Vendas',
            align: 'center',
            floating: true
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          }
        },
      }
      this.modelSalesPerStore = this.dataSalesPerStore
    },

    setDataValuesSalesWithFormOfPayments(response) {
      this.dataValuesSalesWithFormOfPayments.chartOptions.labels = []
      this.dataValuesSalesWithFormOfPayments.series = []
      this.formOfPaymentsLabels = []
      let series = []
      for (let i = 0; i < response[0].valuesSalesWithFormOfPayments.length; i++) {
        this.formOfPaymentsLabels.push(`${response[0].valuesSalesWithFormOfPayments[i].forma_pagamento}`)
        series.push(parseFloat(response[0].valuesSalesWithFormOfPayments[i].venda_total.toFixed(2)))
      }
      
      this.dataValuesSalesWithFormOfPayments.chartOptions = {
        labels: this.formOfPaymentsLabels,
        title: {
          text: 'Vendas por Forma de Pagamento',
          align: 'center',
          floating: false
        },
        chart: {
          type: 'pie',
          width: 300,
          height: 300,
        },
        legend: {
          position: 'bottom',
        },
      }
      this.dataValuesSalesWithFormOfPayments.series = series
      this.modelValuesSalesWithFormOfPaymentsModel = this.dataValuesSalesWithFormOfPayments
    },

    setDataSalesPerSellers(response) {
      this.dataSalesPerSellers.chartOptions.labels = []
      this.dataSalesPerSellers.series = []
      this.salesPerSellersLabels = []
      let series = []
      for (let i = 0; i < response[0].valuesTotalSalesWIthSeller.length; i++) {
        this.salesPerSellersLabels.push(`${response[0].valuesTotalSalesWIthSeller[i].vendedor}`)
        series.push(parseFloat(response[0].valuesTotalSalesWIthSeller[i].venda_total.toFixed(2)))
      }
      this.dataSalesPerSellers.chartOptions = {
        labels: this.salesPerSellersLabels,
        title: {
          text: 'Vendas por Vendedor',
          align: 'center',
          floating: false
        },
        chart: {
          type: 'pie',
          height: 300,
          width: 300,
        },
        legend: {
          position: 'bottom',
        },
        responsive: [{
          breakpoint: 300,
          options: {
            chart: {
              width: 200
            },
          }
        }]
      }
      this.dataSalesPerSellers.series = series
      this.modelSalesPerSellers = this.dataSalesPerSellers
    },

    setTopSellingProducts(response) {
      this.dataTopProductsSales.series = []
      this.dataTopProductsSales.chartOptions.xaxis.categories = []
      this.mobileDataTopProductsSales.series = []
      this.mobileDataTopProductsSales.chartOptions.xaxis.categories = []
      let dataTopProductsSalesLabels = []
      let series = []
      response[0].topSellingProducts.forEach(element => {
        series.push(element.amount)
        dataTopProductsSalesLabels.push(element.description_item)
      });
      this.dataTopProductsSales = {
        series: [{
          data: series
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250,
            width: 800,
          },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          },
          xaxis: {
            categories: dataTopProductsSalesLabels,
            labels: {
              show: false
            },
          },
          title: {
            text: 'Produtos mais vendidos',
            align: 'center',
            floating: true
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          }
        },
      }
      this.mobileDataTopProductsSales = {
        series: [{
          data: series
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250,
            width: 300,
          },
          plotOptions: {
            bar: {
              columnWidth: '30%',
              distributed: true,
            }
          },
          colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          },
          xaxis: {
            categories: dataTopProductsSalesLabels,
            labels: {
              show: false
            },
          },
          title: {
            text: 'Produtos mais vendidos',
            align: 'center',
            floating: true
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          }
        },
      }
      this.modelTopProductsSales = this.dataTopProductsSales
      this.mobileModelTopProductsSales = this.mobileDataTopProductsSales
    },

    setMoneyItens(response) {
      this.dataMoneyItens.series = []
      this.dataMoneyItens.chartOptions.xaxis.categories = []
      this.mobileDataMoneyItens.series = []
      this.mobileDataMoneyItens.chartOptions.xaxis.categories = []
      let dataMoneyItensLabels = []
      let series = []
      response[0].valuesTotalItensSales.forEach(element => {
        series.push(element.sub_total_venda.toFixed(2))
        dataMoneyItensLabels.push(element.descricao_produto)
      });
      this.dataMoneyItens = {
        series: [{
          data: series
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250,
            width: 800,
          },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          colors: ['#f48024', '#69d2e7', '#F0712C', '#f9a3a4', '#90ee7e'],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          },
          xaxis: {
            categories: dataMoneyItensLabels,
            labels: {
              show: false
            },
          },
          title: {
            text: 'Produtos com maior valor total de saída',
            align: 'center',
            floating: true
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          },
        },
      }
      this.mobileDataMoneyItens = {
        series: [{
          data: series
        }],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250,
            width: 300,
          },
          plotOptions: {
            bar: {
              columnWidth: '30%',
              distributed: true,
            }
          },
          colors: ['#f48024', '#69d2e7', '#F0712C', '#f9a3a4', '#90ee7e'],
          dataLabels: {
            enabled: false,
          },
          legend: {
            position: 'bottom'
          },
          xaxis: {
            categories: dataMoneyItensLabels,
            labels: {
              show: false
            },
          },
          title: {
            text: 'Produtos com maior valor total de saída',
            align: 'center',
            floating: true,
            style: {
              fontSize: '11px',
              fontWeight: 800,
            }
          },
          tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
          },
        },
      }
      this.modelMoneyItens = this.dataMoneyItens
      this.mobileModelMoneyItens = this.mobileDataMoneyItens
    }
  },
  watch: {
    getReportChartSales: function (val) {
      this.setDataSales(val);
    },
    getSelectedEnterprise: function (val) {
      this.getDataSales(val.id);
    },
  },
};
</script>
