<template>
  <v-container>
    <v-form :disabled="isDisable" ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field @keyup.enter="choose_product()" outlined label="Produto" append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('product')" v-model="product_select.description_item"
            append-icon="mdi-magnify" return-object @click:append="changeDialog()">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field outlined type="number" v-model="product.amount_add" label="Qtd"
            :rules="[() => !!product.amount_add || 'Campo Obrigatorio']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-text-field prefix="R$" v-model="product.value_cust" type="number" outlined label="Custo"
            :rules="[() => !!product.value_cust || 'Campo Obrigatorio']">
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="Alíquota" append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('aliquot')" v-model="aliquot" :items="aliquot_itens" item-text="aliquot"
            item-value="id" return-object :rules="[() => !!aliquot || 'Campo Obrigatorio']"></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete v-model="cfop" label="CFOP" :items="cfop_itens" return-object
            append-outer-icon="mdi-plus-circle" @click:append-outer="showDialogs('CFOP')" item-text="code" outlined
            :rules="[() => !!cfop.code || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete item-text="description" v-model="cst_csosn" :items="cstCsosn" return-object
            append-outer-icon="mdi-plus-circle" @click:append-outer="showDialogs('CST/CSOSN')" label="CST/CSOSN"
            outlined :rules="[() => !!cst_csosn || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="2" xl="5" md="5" sm="6">
          <v-text-field outlined label="IPI" v-model="product_select.ipi" type="number" suffix="%" :rules="[
            () =>
              !!product_select.ipi ||
              product_select.ipi === 0 ||
              'Campo Obrigatorio',
          ]"></v-text-field>
        </v-col>
        <v-col cols="12" lg="2" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="CST/IPI" append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('CST/IPI')" v-model="product.cst_ipi" :items="cst_ipi_itens"
            item-text="description" item-value="id" return-object
            :rules="[() => !!product.cst_ipi || 'Campo Obrigatorio']"></v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" xl="6" md="5" sm="6">
          <v-autocomplete outlined label="CST/PIS" append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('CST/PIS')" :items="pisCofins_itens" item-text="description" return-object
            v-model="cst_pis" :rules="[() => !!cst_pis || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="3" xl="6" md="6" sm="6">
          <v-autocomplete outlined label="CST/COFINS" append-outer-icon="mdi-plus-circle"
            @click:append-outer="showDialogs('CST/COFINS')" :items="pisCofins_itens" item-text="description"
            return-object v-model="cst_cofins" :rules="[() => !!cst_cofins || 'Campo Obrigatorio']">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="1" xl="1" md="2" sm="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded elevation="5" v-bind="attrs" @click="add_product()" v-on="on" class="success mt-2"
                :disabled="!valid">
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Adicionar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <TableNoteEntryItens :component="component" :actionDelete="deleteItens" :getters="results"
        :actionEdit="changeDialogEdit" />

      <Product v-if="dialog" :component="this" :dialog="dialog" :getters="getProduct" :changeDialog="changeDialog" />
      <editItem v-if="dialogEdit" :dialog="dialogEdit" :changeDialog="changeDialogEdit" :submit="updateItens"
        :component="this" />
      <ShowDialogCFOP />
      <ShowDialogProduct :out_of_register="true" />
      <ShowDialogAliquot />
      <ShowDialogCSOSN />
      <ShowDialogPISCOFINS />
    </v-form>
  </v-container>
</template>

<script>
import Product from "./DialogProduct/";
import editItem from "../../AddDialog/EditItensNote/";
import TableNoteEntryItens from "../../Table/TableNoteEntryItens/index";
import { header } from "./script";
import { GET_PRODUCT_BY_ID } from "../../../../store/actions/product";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";
import { GET_ALL_NOTE_ENTRY_ITENS } from "../../../../store/actions/note_entry_itens";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { UPDATE_ITENS_NOTE } from "../../../../store/actions/note_entry_itens";
import { GET_ALL_CST_IPI } from "../../../../store/actions/cstipi";
import {
  ADD_NOTE_ENTRY_ITENS,
  DESTROY_NOTE_ENTRY_ITENS,
} from "../../../../store/actions/note_entry_itens";

import { UPDATED_LIST_ITEMS } from "../../../../store/actions/preNote_entry";
import ShowDialogCFOP from "../../../cfop/AddDialog";
import ShowDialogProduct from "../../../products/AddDialog";
import ShowDialogAliquot from "../../../aliquot/AddDialog";
import ShowDialogCSOSN from "../../../csosn/AddDialog";
import ShowDialogPISCOFINS from "../../../pis_cofins/AddDialog";
import { mapGetters } from "vuex";

import Methods from "./helpers/Methods"

export default {
  props: {
    component: Object,
    isDisable: Boolean,
  },
  components: {
    ShowDialogProduct,
    Product,
    TableNoteEntryItens,
    editItem,
    ShowDialogAliquot,
    ShowDialogCSOSN,
    ShowDialogCFOP,
    ShowDialogPISCOFINS,
  },
  data: () => ({
    header,
    product_select: Object(),
    cst_pis: Object(),
    cst_cofins: Object(),
    aliquot_itens: Array(),
    cfop_itens: Array(),
    pisCofins_itens: Array(),
    cst_ipi_itens: Array(),

    cstList: Array(),
    csosnList: Array(),

    ObjectToUpdate: Object(),
    cst: Object(),
    csosn: Object(),
    product: Object(),
    valuesParameter: {
      page: null,
      limit: null,
      paginate: false,
    },
    dialog: Boolean(),
    dialogEdit: Boolean(),

    aliquot: Object(),
    cfop: Object(),
    cstCsosn: Array(),
    cst_csosn: String(),
    results: [],
    valid: false,
    itemToUpdate: Object()
  }),
  methods: {
    ...Methods,
    showDialogs(component) {
      if (component == "product") {
        this.$store.dispatch("setShowDialogRegisterProduct", true);
      } else if (component == "CFOP") {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else if (component == "aliquot") {
        this.$store.dispatch("setShowDialogRegisterAliquot", true);
      } else if (component == "CST/IPI") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (component == "CST/PIS") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (component == "CST/CSOSN") {
        this.$store.dispatch("setShowDialogRegisterCSOSN", true);
      } else if (component == "CST/COFINS") {
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      }
    },
    changeDialog() {
      this.dialog = !this.dialog;
    },

    changeDialogEdit(item) {
      this.itemToUpdate = {...item}
      this.dialogEdit = !this.dialogEdit;
    },

    async choose_product() {
      const payload = {
        id: this.product_select.description_item,
        enterprise_id: this.component.invoice.enterprise_id
      }
      await this.$store.dispatch(GET_PRODUCT_BY_ID, payload);
    },

    refresh_dates() {
      this.cstList = Object.keys(this.getCst).map((key) => this.getCst[key]);
      this.csosnList = Object.keys(this.getCsosn).map(
        (key) => {
          if(typeof(this.getCsosn[key].code) == "string"){
            this.getCsosn[key].code = parseFloat(this.getCsosn[key].code)
            return this.getCsosn[key]
          }else {
            return this.getCsosn[key]
          }
        }
      );
      this.cstCsosn = this.cstList.concat(this.csosnList);
    },

    GetAll() {
      this.$store.dispatch(
        GET_ALL_NOTE_ENTRY_ITENS,
        this.component.object.grade_itens
      );
    },

    async GetCst() {
      await this.$store.dispatch(GET_ALL_CST, this.valuesParameter);
    },

    async GetCsosn() {
      await this.$store.dispatch(GET_ALL_CSOSN, this.valuesParameter);
    },

    async GetCfop() {
      this.$store.dispatch(GET_ALL_CFOP, this.valuesParameter);
    },

    async GetAliquot() {
      this.$store.dispatch(GET_ALL_ALIQUOT, this.valuesParameter);
    },

    async GetPisCofins() {
      this.$store.dispatch(GET_ALL_PIS_COFINS, this.valuesParameter);
    },
    async GetCstIpi() {
      this.$store.dispatch(GET_ALL_CST_IPI, this.valuesParameter);
    },

    async add_product() {
       
      let products = {
        aliquot_ipi_product: parseFloat(this.product_select.ipi),
        cust: parseFloat(this.product.value_cust),
        amount: parseFloat(this.product.amount_add),
        description_product: this.product_select.description_item,
        description_unit: this.product_select.unit.sigla,
        cst_code_ipi: String(this.product.cst_ipi.code),
        aliquot_icms_product: parseFloat(this.aliquot.aliquot),
        cst_pis_id: this.product_select.cst_pis_id,
        cst_confis_id: this.product_select.cst_cofins_id,
        product_id: this.product_select.id,
        cfop_id: this.cfop.id,
        csosn_id: this.product_select.csosn_id,
        cst_id: this.product_select.cst_id,
        invoiceentry_id: null,
        cst_code_pis: this.cst_pis.code,
        cst_code_confis: this.cst_cofins.code,
        code_cfop: String(this.cfop.code),
        code_csosn: this.product_select.csons.code,
        code_cst: this.product_select.cst.code,
        unit_id: this.product_select.unit_id,
        value_sub_total_product: 0,
        value_icms_st_product: 0,
        value_accounting_product: 0,
        value_base_ipi_product: 0,
        value_base_icms_st_product: 0,
        value_ipi_product: 0,
        value_icms_product: 0,
        value_free_icms_product: 0,
        value_icms_outher_product: 0,
        value_ipi_free_product: 0,
        value_ipi_outher_product: 0,
        value_descont: 0,
        value_base_icms_product: 0,
        aliquot_pis: 0,
        aliquot_confis: 0,
        value_base_pis_product: 0,
        value_pis_product: 0,
        value_base_confis_product: 0,
        value_confis_product: 0,
        value_base_pis_st_product: 0,
        value_pis_st_product: 0,
        value_base_cofins_st_product: 0,
        value_cofins_st_product: 0,
        value_other_pis_product: 0,
        value_other_confis_product: 0,
        value_free_confis_product: 0,
        value_free_pis_product: 0,
      };
      this.results.push(products)
      this.$store.dispatch(UPDATED_LIST_ITEMS, this.results)
      await this.$store.dispatch(ADD_NOTE_ENTRY_ITENS, products);
      this.wipeData();
      this.$refs.form.resetValidation()
    },

    deleteItens(item) {
      if (!this.isDisable) {
        try {
          this.$store.dispatch(DESTROY_NOTE_ENTRY_ITENS, item.tableId);
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },

    clearItens() {
      this.count = 0;
      try {
        this.$store.dispatch(DESTROY_NOTE_ENTRY_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },

    lookGetNoteEntryItens(itens) {
      let aux = JSON.parse(JSON.stringify(itens));
      if (aux.length > 0) {
        aux.forEach((e) => {
          e.amount = parseFloat(e.amount).toFixed(3).replace(".", ",");
          e.value_icms_product = parseFloat(e.value_icms_product).toFixed(2);
        });
        this.results = aux;
      } else {
        this.results = Array();
      }
    },

    async updateItens(val) {
      this.itemToUpdate.amount = parseInt(this.itemToUpdate.amount)
      const intemToUpdate = {...this.itemToUpdate}
       
      await this.$store.dispatch(UPDATE_ITENS_NOTE, intemToUpdate).then((response) => {
        const index = this.results.findIndex((element) => element.id == response.id)
        this.results.splice(index, 1, response)
        this.changeDialogEdit(val);
      });
    },
  },
  computed: {
    ...mapGetters([
      "getAliquotToAdd",
      "getProduct",
      "getCfop",
      "getCfopToAdd",
      "getCst",
      "getCsosn",
      "getAliquot",
      "getNoteEntryItens",
      "getPisCofins",
      "getCstIpi",
      "getCsosnToAdd",
      "getProductById",
      "getItemToAddPisConfins",
    ]),
  },
  async created() {
    if (this.component.object) {
      await this.GetAll();
      this.$nextTick(this.lookGetNoteEntryItens(this.getNoteEntryItens));
    }

    await this.GetCst();
    await this.GetCsosn();
    await this.GetCfop();
    await this.GetAliquot();
    await this.GetPisCofins();
    await this.GetCstIpi();
    await this.refresh_dates();
  },

  watch: {
    // description: function (val) {
    //   if (val.id !== undefined) {
    //     this.product_select = { ...val.data[0] };
    //   }
    // },
    getCsosnToAdd:function(value){
      this.cstCsosn.push(value)
      this.cst_csosn = value
    },
    getCfopToAdd: function (value) {
      this.cfop_itens.push(value);
      this.cfop = value
    },
    product_select: function (value) {
       
      if (value.id !== undefined) {
        this.product.value_cust = value.cost_price;
        this.aliquot = value.aliquot;
        this.cst_csosn = value.csons;
        this.product_select.ipi = value.ipi;
        this.cst_pis = value.cst_pis;
        this.cst_cofins = value.cst_confis
      }
       
    },
    getNoteEntryItens: function (val) {
      this.lookGetNoteEntryItens(val);
    },

    getProductById: function (val) {
      this.product_select = { ...val.data[0] };
    },

    getAliquot: function (val) {
      this.aliquot_itens = Object.keys(val).map((key) => val[key]);
    },

    getCfop: function (val) {
      let aux = Object.keys(val).map((key) => val[key]);
      this.cfop_itens = aux.filter((e) => {
        return String(e.code).startsWith('1') || String(e.code).startsWith('2');
      });
    },

    getPisCofins: function (val) {
      this.pisCofins_itens = Object.keys(val).map((key) => val[key]);
    },

    getItemToAddPisConfins: function(val){
        this.pisCofins_itens.push(val)
        this.cst_cofins = val
    },

    getCstIpi: function (val) {
      this.cst_ipi_itens = Object.keys(val).map((key) => val[key]);
    },
    getAliquotToAdd: function(val){
      
      this.aliquot_itens.push(val)
      this.aliquot = val.id
    },
    aliquot: function (val) {
      this.product.aliquot = val.aliquot;
      this.product.aliquot_id = val.id;
    },

    cfop: function (val) {
      this.product.description_cfop_code = val.code;
      this.product.cfop_id = val.id;
    },
  },

  destroyed() {
    this.clearItens();
  },
};
</script>

<style>
</style>