const seguranca = [
    {
      "text": "Usuario",
      "icon": "mdi-account-plus-outline",
      "route": "/User",
     "class": "white--text",
      "active": false,
    },
    {
      "text": "Permissão",
      "icon": "mdi-truck-outline",
      "route": "/Permission",
      "class": "white--text",
      "active": false,
    },
    // {
    //   "text": "Parâmetros",
    //   "icon": "mdi-cog-outline",
    //   "route": "/Parameter",
    //   "class": "white--text",
    //   "active": false,
    // },
    {
      "text": "Parâmetros",
      "icon": "mdi-table-cog",
      "route": "/Parameters",
      "class": "white--text",
      "active": true,
    },
    {
      "text": "Configurações",
      "icon": "mdi-cog-outline",
      "route": "/Settings",
      "class": "white--text",
      "active": true,
    },
  ]
  export { seguranca };
  