<template>
  <div>
    <!-- computador tablet -->
    <div class="d-none d-sm-flex d-lg-flex d-xl-flex">
      <v-col>
        <v-form v-model="valid">
          <v-row>
            <v-col
              ><v-card-title class="greyFont mt-3">
                Baixa de XML Emitido</v-card-title
              ></v-col
            >
            <v-col>
              <v-row class="d-flex justify-end mt-3">
                <div class="select">
                  <v-select
                    dense
                    v-model="enterpriseSelected"
                    :items="listEmterprise"
                    item-text="fantasyName"
                    item-value="id"
                    outlined
                    label="Empresa"
                  />
                </div>
                <div class="vbtndownload">
                  <v-btn
                    @click="downloadXML"
                    :disabled="!valid"
                    :loading="loading"
                    color="success"
                    class="mb-1"
                    width="100%"
                    >Baixar</v-btn
                  >
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-text-field
                class="ma-2"
                outlined
                label="Data Inicial"
                type="date"
                v-model="formState.initialDate"
                :rules="[
                  () => !!formState.initialDate|| 'Campo Obrigatorio',
                ]"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-text-field
                class="ma-2"
                outlined
                label="Data Final"
                type="date"
                v-model="formState.finalDate"
                :rules="[
                  () => !!formState.finalDate|| 'Campo Obrigatorio',
                ]"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" xl="4" lg="4">
              <v-select
                class="ma-2"
                outlined
                v-model="formState.nfeType"
                :rules="[
                  () => !!formState.nfeType|| 'Campo Obrigatorio',
                ]"
                item-text="text"
                item-value="value"
                :items="headers"
                label="Tipo de nota fiscal"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      
      <AlertComponent :active="alert.showAlert" :message="alert.message" />
      <LoadData :component="this" :title="title"/>
    </div>
    
  </div>
</template>

<script>
import LocalState from "./helpers/localState";
import Methods from "./helpers/methods";
import Watches from "./helpers/watch";

import LoadData from "../../../components/load_data/index.vue"
import AlertComponent from "../../../components/alert_api/alert_api.vue";

import { getters } from "./helpers/gettersVuex";
import { mapGetters } from "vuex";

export default {
  components: {
    AlertComponent,
    LoadData
  },
  data: function () {
    return {
      ...LocalState,
    };
  },
  methods: {
    ...Methods,
  },
  computed: {
    ...mapGetters(getters),
  },
  watch: {
    ...Watches,
  },
  created() {
    this.getAllEnterprise();
  },
};
</script>

<style>
@import "./css/card_title_css.css";
</style>