<template>
  <v-form v-model="valid">
    <v-row class="ml-2">
      <v-col>
        <v-text-field
          v-model="component.date.date_initial"
          label="Data inicial do vencimento"
          type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          outlined
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="component.date.date_final"
          label="Data final do vencimento"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          type="date"
          outlined
        >
        </v-text-field>
      </v-col>
      
      
      <div style="margin-top: 12px;">
        <v-btn
        class="mt-2"
        :disabled="!valid"
        color="success"
        rounded
        @click="component.cancelFind()"
        ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <v-col
      ><v-btn
      class="mt-2"
      :disabled="!valid"
      color="success"
      rounded
      @click="component.find()"
      ><v-icon>mdi-magnify</v-icon></v-btn
      ></v-col
      >

      <v-col cols="12" sm="12" md="3" lg="2">
        <v-select
          v-model="component.id_cashier"
          :items="this.component.cashier"
          item-text="description"
          item-value="id"
          outlined
          label="Caixa"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="12" md="3" lg="2">
        <v-text-field
          v-model="component.date_low"
          outlined
          type="date"
          label="Data da Baixa"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="component.total"
          prefix="R$"
          readonly
          outlined
          label="Valor"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: Boolean(),
    data: String(),
  }),
};
</script>

<style>
</style>