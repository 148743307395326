const GET_ALL_BILLSRECEIVE = "GET_ALL_BILLSRECEIVE"
const UPDATE_BILLSRECEIVE = "UPDATE_BILLSRECEIVE"
const DESTROY_BILLSRECEIVE = "DESTROY_BILLSRECEIVE"
const ADD_BILLSRECEIVE = "ADD_BILLSRECEIVE"
const DOWNLOAD_BILLSRECEIVE = "DOWNLOAD_BILLSRECEIVE"
const FIND_BILLSRECEIVE = "FIND_BILLSRECEIVE"
const AUTO_COMPLETE = "AUTO_COMPLETE"
const VIEW_BILLS_RECEIVE = "VIEW_BILLS_RECEIVE"
const GET_BILLS_RECEIVE_BY_STATUS = "GET_BILLS_RECEIVE_BY_STATUS"
const BILLS_RECEIVE_REVERSAL = "BILLS_RECEIVE_REVERSAL"
const AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS = "AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS"
const GET_ALL_BILLS_RECEIVE_BY_DATE = "GET_ALL_BILLS_RECEIVE_BY_DATE"
const DOWNLOAD_BILLSRECEIVE_MULTIPLE = "DOWNLOAD_BILLSRECEIVE_MULTIPLE"
const GET_ALL_BILLSRECEIVE_BY_ENTERPRISE = "GET_ALL_BILLSRECEIVE_BY_ENTERPRISE"
const AUTO_COMPLETE_BY_ENTERPRISE = "AUTO_COMPLETE_BY_ENTERPRISE"
const GET_REPORT_BILLSRECEIVE = "GET_REPORT_BILLSRECEIVE"


module.exports = {
    GET_ALL_BILLSRECEIVE,
    UPDATE_BILLSRECEIVE,
    DESTROY_BILLSRECEIVE,
    ADD_BILLSRECEIVE,
    DOWNLOAD_BILLSRECEIVE,
    FIND_BILLSRECEIVE,
    AUTO_COMPLETE,
    VIEW_BILLS_RECEIVE,
    GET_BILLS_RECEIVE_BY_STATUS,
    BILLS_RECEIVE_REVERSAL,
    AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS,
    GET_ALL_BILLS_RECEIVE_BY_DATE,
    DOWNLOAD_BILLSRECEIVE_MULTIPLE,
    GET_ALL_BILLSRECEIVE_BY_ENTERPRISE,
    AUTO_COMPLETE_BY_ENTERPRISE,
    GET_REPORT_BILLSRECEIVE
}