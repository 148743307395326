import axios from "axios";
const {
    ADD_COST_CENTER,
    GET_ALL_COST_CENTER,
    AUTOCOMPLETE_COST_CENTER,
    UPDATE_COST_CENTER,
    DELETE_COST_CENTER,
} = require("./actions/cost_center");
const {
  SPLICE_ERRO
} = require("./actions/alert_api");

const moduleCostCenter = {
  state: () => ({
    costCenters: Object()
  }),

  mutations: {
    GET_ALL_COST_CENTER: (state, payload) => {
      state.costCenters = payload;
    },

    AUTOCOMPLETE_COST_CENTER: (state, payload) => {
      state.costCenters = payload;
    },

  },
  actions: {
    GET_ALL_COST_CENTER({
      commit
    }, payload) {
      axios.post("centercost/getAll", payload).then((response) => {
        commit(GET_ALL_COST_CENTER, response.data);
      });
    },

    AUTOCOMPLETE_COST_CENTER({
      commit
    }, payload) {
      axios.post("centercost/autocomplete", payload).then((response) => {
        commit(AUTOCOMPLETE_COST_CENTER, response.data);
      })
    },

    async ADD_COST_CENTER({commit}, payload) {
      try {
        const response = await axios.post("centercost/create", payload)
        commit(SPLICE_ERRO, {
          alert: "Adicionado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },

    async UPDATE_COST_CENTER({commit}, payload) {
      try {
        const response = await axios.post("centercost/update", payload)
        commit(SPLICE_ERRO, {
          alert: "Atualizado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },

    async DELETE_COST_CENTER({commit}, payload) {
      try {
        const response = await axios.post("centercost/delete", payload)
        commit(SPLICE_ERRO, {
          alert: "Deletado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },
  },

  getters: {
    getCostCenter: (state) => ({
      ...state.costCenters
    }),
  },
};

export default moduleCostCenter;