let dataMoneyItens = {
  series: [{
    data: []
  }],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 250,
      width: 800,
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    colors: ['#f48024', '#69d2e7', '#F0712C', '#f9a3a4', '#90ee7e'],
    dataLabels: {
      enabled: false,
    },

    legend: {
      position: 'bottom'
    },

    xaxis: {
      categories: [],
      labels: {
        show: false
      },
    },
    title: {
      text: 'Produtos com maior valor total de saída',
      align: 'center',
      floating: true
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    },
  },
}

let mobileDataMoneyItens = {
  series: [{
    data: []
  }],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 250,
      width: 300,
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        distributed: true,
      }
    },
    colors: ['#f48024', '#69d2e7', '#F0712C', '#f9a3a4', '#90ee7e'],
    dataLabels: {
      enabled: false,
    },
    
    legend: {
      position: 'bottom'
    },
    
    xaxis: {
      categories: [],
      labels: {
        show: false
      },
    },
    title: {
      text: 'Produtos com maior valor total de saída',
      align: 'center',
      floating: true
    },
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    },
  },
}

let modelMoneyItens = {
  chartOptions: {
    chart: {
      type: 'bar',
      height: 250,
      width: 800,
    },
  },
}

let mobileModelMoneyItens = {
  chartOptions: {
    chart: {
      type: 'bar',
      height: 250,
      width: 300,
    },
  },
}

export { dataMoneyItens, modelMoneyItens, mobileDataMoneyItens, mobileModelMoneyItens }