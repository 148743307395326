var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{model:{value:(_vm.component.generateDataForm),callback:function ($$v) {_vm.$set(_vm.component, "generateDataForm", $$v)},expression:"component.generateDataForm"}},[_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.component.justVisible,"label":"Status","disabled":"","items":_vm.status,"outlined":""},model:{value:(_vm.component.object.status),callback:function ($$v) {_vm.$set(_vm.component.object, "status", $$v)},expression:"component.object.status"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":_vm.component.justVisible,"label":"*Data Emissao","type":"date","outlined":"","required":"","rules":[
          () => !!_vm.component.object.issuance_date || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.issuance_date &&
              _vm.component.object.issuance_date.length >= 2) ||
            'Campo tem que ser maior do que 2',
        ]},model:{value:(_vm.component.object.issuance_date),callback:function ($$v) {_vm.$set(_vm.component.object, "issuance_date", $$v)},expression:"component.object.issuance_date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":_vm.component.justVisible,"label":"*Data Vencimento","type":"date","outlined":"","required":"","rules":[
          () => !!_vm.component.object.issuance_due || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.issuance_due &&
              _vm.component.object.issuance_due.length >= 0) ||
            'Campo tem que ser maior do que 0',
        ]},model:{value:(_vm.component.object.issuance_due),callback:function ($$v) {_vm.$set(_vm.component.object, "issuance_due", $$v)},expression:"component.object.issuance_due"}})],1),_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.component.justVisible,"label":"*Tipo do Titulo","items":_vm.title,"outlined":"","required":"","rules":[
          () => !!_vm.component.object.type_title || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.type_title &&
              _vm.component.object.type_title.length >= 2) ||
            'Campo tem que ser maior do que 2',
        ]},model:{value:(_vm.component.object.type_title),callback:function ($$v) {_vm.$set(_vm.component.object, "type_title", $$v)},expression:"component.object.type_title"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":_vm.component.justVisible,"label":"*Numero do Titulo","outlined":"","maxlength":"15","counter":"15","required":"","rules":[
          () => !!_vm.component.object.number_title || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.number_title &&
              _vm.component.object.number_title.length >= 0) ||
            'Campo tem que ser maior do que 0',
        ]},model:{value:(_vm.component.object.number_title),callback:function ($$v) {_vm.$set(_vm.component.object, "number_title", $$v)},expression:"component.object.number_title"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":_vm.component.justVisible,"label":"*Clientes","append-outer-icon":"mdi-plus-circle","append-icon":"mdi-magnify","outlined":"","required":"","rules":[
          () => !!_vm.component.object.client.people_name || 'Campo Obrigatorio',
          () =>
            (!!_vm.component.object.client.people_name &&
              _vm.component.object.client.people_name.length >= 1) ||
            'Campo tem que ser maior do que 1',
        ]},on:{"click:append-outer":_vm.showRegisterClientDialog,"click:append":function($event){_vm.dialog = !_vm.dialog}},model:{value:(_vm.component.object.client.people_name),callback:function ($$v) {_vm.$set(_vm.component.object.client, "people_name", $$v)},expression:"component.object.client.people_name"}})],1)],1),_c('v-dialog',{attrs:{"max-width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_vm._v("Clientes")]),_c('v-switch',{attrs:{"label":"Pessoa Jurídica","color":"primary"},model:{value:(_vm.isFormalPeople),callback:function ($$v) {_vm.isFormalPeople=$$v},expression:"isFormalPeople"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Pesquisar","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setAutocompleteClient()}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.atual.header,"items":_vm.atual.getter,"hide-default-footer":"","no-data-text":"Sem registros"},on:{"click:row":_vm.rowClicked}}),_c('v-divider'),_c('v-pagination',{staticClass:"mb-6",attrs:{"length":_vm.atualTotal,"total-visible":10,"color":"success"},model:{value:(_vm.atualCurrent),callback:function ($$v) {_vm.atualCurrent=$$v},expression:"atualCurrent"}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"success",attrs:{"text":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Fechar")])],1)],1)],1)],1),_c('RegisterClient',{attrs:{"out_of_register":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }