<template>
  <div>
    <v-container>
      <v-form :disabled="isDisable" v-model="component.validGenerateDate">
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-autocomplete outlined color="success" :items="taxoperationtype" item-text="description" item-value="id"
              label="*Tipo de Operação" append-outer-icon="mdi-plus-circle"
              @click:append-outer="callDialogs('operationtypedialog')"
              v-model="component.invoice.tax_operation_types_id" :rules="[
                () =>
                  !!component.invoice.tax_operation_types_id ||
                  'Campo Obrigatorio',
              ]"></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-autocomplete outlined color="success" :items="enterprises" item-text="fantasyName" item-value="id"
              label="*Empresa" append-outer-icon="mdi-plus-circle" @click:append-outer="callDialogs('enterprisedialog')"
              v-model="component.invoice.enterprise_id" :rules="[(v) => !!v || 'Campo Obrigatorio']"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-text-field label="*Chave de Acesso da NFE" v-model="component.invoice.access_key" outlined :rules="[
              () => !!component.invoice.access_key || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.access_key &&
                  component.invoice.access_key.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xl="6">
            <v-text-field label="*Prot. Autorização de uso" v-model="component.invoice.authorization_protocol" outlined
              :rules="[
                () =>
                  !!component.invoice.authorization_protocol ||
                  'Campo Obrigatorio',
                () =>
                  (!!component.invoice.authorization_protocol &&
                    component.invoice.authorization_protocol.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ]"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field v-model="component.provider_name"
              readonly
               outlined
              append-icon="mdi-magnify" label="*Fornecedor" append-outer-icon="mdi-plus-circle"
              @click:append-outer="callDialogs('providerdialog')" @click:append="openAddProvider" :rules="[
                (v) => !!v || 'Campo Obrigatorio',
              ]">
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field label="*Número da Nota" v-model="component.invoice.number_grade" outlined :rules="[
              () => !!component.invoice.number_grade || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.number_grade &&
                  component.invoice.number_grade.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]"></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field label="*Série" v-model="component.invoice.series" outlined :rules="[
              () => !!component.invoice.series || 'Campo Obrigatorio',
              () =>
                (!!component.invoice.series &&
                  component.invoice.series.length >= 1) ||
                'Campo tem que ser maior do que 1',
            ]"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field label="*Data de Emissão" placeholder="null" type="Date"
              v-model="component.invoice.date_of_issue" outlined :rules="[
                () => !!component.invoice.date_of_issue || 'Campo Obrigatorio',
                () =>
                  (!!component.invoice.date_of_issue &&
                    component.invoice.date_of_issue.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ]"></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field label="*Data de Entrada" v-model="component.invoice.entry_date" type="Date" placeholder="null"
              outlined :rules="[
                () => !!component.invoice.entry_date || 'Campo Obrigatorio',
                () =>
                  (!!component.invoice.entry_date &&
                    component.invoice.entry_date.length >= 2) ||
                  'Campo tem que ser maior do que 2',
              ]"></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="4" xl="4">
            <v-text-field outlined label="Informações Adicionais" v-model="component.invoice.note"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <TableProvider :component="this" title="Fornecedor" />
      <OperationTypeDialog v-if="getShowDialogRegisterTaxOperationType" />
      <EnterpriseDialog v-if="getShowDialogRegisterEnterprise" />
      <ProviderDialog out_of_register="true" v-if="getShowDialogRegisterProvider" />
    </v-container>
  </div>
</template>

<script>

import {
  GET_AUTOCOMPLETE_PROVIDER,
  GET_ALL_PROVIDER,
} from "../../../../store/actions/provider";
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../store/actions/tax_operation_types";
import { GET_ALL_ENTERPRISE } from "../../../../store/actions/enterprise";
import { mapGetters } from "vuex";

import OperationTypeDialog from "../../../tax_operation_type/AddDialog";
import EnterpriseDialog from "../../../enterprise/AddDialog";
import ProviderDialog from "../../../provider/AddDialog";
import ModalDataTable from "../../../Utils/SelectTable/index.vue"
import {headersLegalPeople,} from "./headersTable"
import TableProvider from "./ProviderTable/index.vue"

export default {
  props: {
    component: Object(),
    isDisable: Boolean(),
  },
  components: {
    OperationTypeDialog,
    EnterpriseDialog,
    ProviderDialog,
    ModalDataTable,
    TableProvider
  },
  data: () => ({
    addDialogProvider: Boolean(),
    description: String(),
    provider_id: Number(),
    taxoperationtype: Array(),
    providers: Array(),
    enterprises: Array(),
    //
    selectTableShow: Boolean(),
    togleTypePeople: Boolean(),

    listPeople: Array(),

    listProviderLegalPeople: Array(),

    headersSelectTable: headersLegalPeople,

    paginateInf: {
      currentPage: 1,
      lastPage: 1
    },

    searchInputProvider: String(),
    searchProvider: String(),
  }),
  methods: {

    

    async GetAllLegalProvider(page = 1) {
      
      const payload = {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
      }
     
      await this.$store.dispatch(GET_ALL_PROVIDER, payload ).then((response) => {
        this.listProviderLegalPeople = [...response.data]
        this.listPeople = [...response.data]
        this.paginateInf.currentPage = response.current_page
        this.paginateInf.lastPage = response.last_page
          // 
      });
     
    },

    async getAutocompleteLegalProvider(page = 1) {
      
      const payload = {
        type_people: "LegalPeople",
        page: page,
        limit: 10,
        paginate: true,
        search: this.searchProvider,
        enterprise_id: 1
      }
     
      await this.$store.dispatch(GET_AUTOCOMPLETE_PROVIDER, payload ).then((response) => {
        this.listProviderLegalPeople = [...response.data]
        this.listPeople = [...response.data]
        this.paginateInf.currentPage = response.current_page
        this.paginateInf.lastPage = response.last_page
      });
     
    },

    rowClickSelectTalbe(item){
        this.component.provider_name = item.people.legalpeople.socialName
        this.component.select_provider = item
        if(this.searchProvider.length >= 2) {
          this.component.select_provider.provider_id = item.pivot.provider_id
        }
        this.openAddProvider()
    },
    openAddProvider(){
      this.selectTableShow = !this.selectTableShow
    },
    callDialogs(dialogs) {
      if (dialogs == "operationtypedialog") {
        this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      } else if (dialogs == "enterprisedialog") {
        this.$store.dispatch("setShowDialogRegisterEnterprise", true);
      } else if (dialogs == "providerdialog") {
        this.$store.dispatch("setShowDialogRegisterProvider", true);
      }
    },
    async GetAllTaxOperationType() {
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async getEnterprise() {
      this.$store.dispatch(GET_ALL_ENTERPRISE, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    mountedTaxOperationType() {
      let tax = Object.values(this.getTaxOperationType);
      this.taxoperationtype = tax.filter((e) => e.type == "ENTRADA");
    },

    setAutocompleteProvider() {
      if(this.searchInputProvider.length >=2) {
        this.searchProvider = this.searchInputProvider
      }
    },
  },
  created() {
    // this.getListLegalProviders();
    this.getEnterprise();
    // this.GetAllLegal();
    this.GetAllTaxOperationType();
    this.GetAllLegalProvider()
  },

  computed: {
    ...mapGetters([
      "getProviderAuto",
      "getTaxOperationType",
      "getEnterprises",
      "getShowDialogRegisterTaxOperationType",
      "getShowDialogRegisterEnterprise",
      "getShowDialogRegisterProvider",
      "getLegalProvider",
      "getNewTaxOperationToAdd",
      "getNewProviderToAdd"
    ]),
  },

  watch: {
    togleTypePeople: function(val){
      if(val){
        this.headersSelectTable = [...this.headersLegalPeople]
        this.listPeople = [...this.listProviderLegalPeople]
      }else{
        this.headersSelectTable = [...this.headersPhysicalPeople]
        this.listPeople = [...this.listProviderPhysicalPeople]
      }
    },
    getEnterprises: function (val) {
      this.enterprises = Object.values(val);
    },
    getProviderAuto: function (val) {
      
      this.providers = val;
    },
    getTaxOperationType: function () {
      this.mountedTaxOperationType();
    },
    getNewProviderToAdd: function (value) {
      
      var newProvider = {
        ...value,
        people: {
          legalpeople: {
            fantasyName: value.fantasyName
          },
        }
      }
      this.providers.push(newProvider)
      this.component.select_provider = value.id
    },
    getNewTaxOperationToAdd: function (value) {
      if (value.type == 1) {
        this.taxoperationtype.push(value)
        this.component.invoice.tax_operation_types_id = value.id
      }
    },
    "paginateInf.currentPage": function (val) {
      if(this.searchProvider.length < 2) {
        this.GetAllLegalProvider(val)
      } else {
        this.getAutocompleteLegalProvider(val)
      }
    },

    searchProvider: function (val) {
      if(val.length >= 2)
      this.getAutocompleteLegalProvider()
    },

    searchInputProvider: function (val) {
      if(val.length == 0) {
        this.GetAllLegalProvider()
        this.searchProvider = ""
      }
    },
  },
};
</script>

<style>
</style>