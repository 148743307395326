import axios from "axios";
const {
    ADD_SETTINGS,
    GET_ALL_SETTINGS,
    AUTOCOMPLETE_SETTINGS,
    UPDATE_SETTINGS,
    DELETE_SETTINGS,
} = require("./actions/settings");
const {
  SPLICE_ERRO
} = require("./actions/alert_api");

const moduleCostCenter = {
  state: () => ({
    settings: Object()
  }),

  mutations: {
    GET_ALL_SETTINGS: (state, payload) => {
      state.settings = payload;
    },

    AUTOCOMPLETE_SETTINGS: (state, payload) => {
      state.settings = payload;
    },

  },
  actions: {
    GET_ALL_SETTINGS({
      commit
    }, payload) {
      axios.post("setting/getAllByEnterprise", payload).then((response) => {
        commit(GET_ALL_SETTINGS, response.data);
      });
    },

    AUTOCOMPLETE_SETTINGS({
      commit
    }, payload) {
      axios.post("setting/autocomplete", payload).then((response) => {
        commit(AUTOCOMPLETE_SETTINGS, response.data);
      })
    },

    async ADD_SETTINGS({commit}, payload) {
      try {
        const response = await axios.post("setting/create", payload)
        commit(SPLICE_ERRO, {
          alert: "Adicionado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },

    async UPDATE_SETTINGS({commit}, payload) {
      try {
        const response = await axios.post("setting/update", payload)
        commit(SPLICE_ERRO, {
          alert: "Atualizado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },

    async DELETE_SETTINGS({commit}, payload) {
      try {
        const response = await axios.post("setting/delete", payload)
        commit(SPLICE_ERRO, {
          alert: "Deletado com sucesso!",
          type: 'S'
        })
        return response
      } catch (error) {
        return error.response.data.error
      }
    },
  },

  getters: {
    getSetting: (state) => ({
      ...state.settings
    }),
  },
};

export default moduleCostCenter;