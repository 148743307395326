<template>
    <Container>
      <v-card elevation="5">
        <v-card-text>
          <CardTitle :component="this" />
        </v-card-text>
        <v-card-text>
          <v-text-field
            class="mx-4"
            label="Pesquisar"
            outlined
            v-model="searchInput"
            append-icon="mdi-magnify"
            @keyup.enter="setAutocomplete()"
          ></v-text-field>
        </v-card-text>
        <Table :component="this" />
      </v-card>
      <AddDialog :component="this" v-if="registerDialog" />

      <AlertApi />
      <loadScreen />
    </Container>
  </template>
  
  <script>
  import Container from "../components/Utils/Container/Container";
  import CardTitle from "../components/settings/CardTitle/index.vue";
  import Table from "../components/settings/Table/index.vue";
  import AddDialog from "../components/settings/AddDialog/index.vue";
  import loadScreen from "../components/load/";
  import AlertApi from "../components/alert_api/alert_api";
  
  import { GET_ALL_SETTINGS, AUTOCOMPLETE_SETTINGS, DELETE_SETTINGS, ADD_SETTINGS, UPDATE_SETTINGS } from "../store/actions/settings"
  import { mapGetters } from "vuex";
  
  export default {
    components: {
      Container,
      CardTitle,
      Table,
      AddDialog,
      AlertApi,
      loadScreen,
    },
  
    data: () => ({
      search: String(),
      searchInput: String(),
      registerDialog: Boolean(),
      settingToUpdate: Object(),
      id: null,
      justView: Boolean(),
      selectEnterprise: Object(),
      enterprises: Array(),
    }),
  
    methods: {
        GetAll(page = 1) {
            const payload = {
              page: page,
              limit: 10,
              paginate: true,
              enterprise_id: this.selectEnterprise.id
            }
            this.$store.dispatch(GET_ALL_SETTINGS, payload)
        },

        setAutocomplete() {
            this.search = this.searchInput
        },

        getAutocomplete(page = 1) {
          const payload = {
              search: this.search,
              page: page,
              limit: 10,
              paginate: true,
              enterprise_id: this.selectEnterprise.id
            }
            this.$store.dispatch(AUTOCOMPLETE_SETTINGS, payload)
        },

        loadItemToUpdate(setting) {
          this.settingToUpdate = setting
          this.id = setting.id
          this.setRegisterDialog()
        },

        loadItemToView(setting) {
          this.justView = true
          this.settingToUpdate = setting
          this.id = setting.id
          this.setRegisterDialog()
        },

        async create(payload) {
          await this.$store.dispatch(ADD_SETTINGS, payload).then((response) => {
              this.setRegisterDialog()
              this.GetAll()
        });
        },

        async update(payload) {
          await this.$store.dispatch(UPDATE_SETTINGS, payload).then((response) => {
              this.setRegisterDialog()
          });
        },

        async deleteItem(setting) {
          const payload = {
            id: setting.id
          }
          
          this.$store.dispatch(DELETE_SETTINGS, payload).then((response) => {
              this.GetAll()
        });
        },

        setRegisterDialog() {
          this.registerDialog = !this.registerDialog
        },

    },
  
    watch: {
        search: function(val) {
            if(val.length >= 2) {
                this.getAutocomplete()
            }
        },

        searchInput: function(val) {
            if(val.length == 0) {
                this.GetAll()
                this.search = ""
            }
        },

        selectEnterprise: function() {
          this.GetAll()
        }
    },
  };
  </script>