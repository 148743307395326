<template>
  <div class="mainFrame">
    <v-container>
      <v-card elevation="5" width="90%"> <CardTitle /></v-card>
      <loadScreen />
    </v-container>
  </div>
</template>

<script>
import CardTitle from "../components/write_off_xml/CardTitle/index.vue";
import Table from "../components/write_off_xml/Table/index.vue";
import loadScreen  from "../components/load/"
export default {
  data: function () {
    return {};
  },
  components: {
    CardTitle,
    Table,
    loadScreen
  },
  methods: {},
  computed: {},
  watch: {},
  created() {},
};
</script>

<style>
.mainFrame {
  width: 100vw;
  display: flex;
  justify-content: center;
}
</style>