var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.getShowDialogRegisterAccountPlan),callback:function ($$v) {_vm.getShowDialogRegisterAccountPlan=$$v},expression:"getShowDialogRegisterAccountPlan"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Plano de Contas ")]),_c('v-container',[_c('v-divider'),_c('v-form',{ref:"form",staticClass:"mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.justVisible,"items":_vm.statusList,"label":"Status","outlined":"","rules":[() => !!_vm.status || 'Campo Obrigatório']},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Código Contabil","readonly":_vm.justVisible,"outlined":"","required":"","rules":[
              () => !!_vm.accountCode || 'Campo Obrigatório',
              () =>
                (!!_vm.accountCode && _vm.accountCode.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]},model:{value:(_vm.accountCode),callback:function ($$v) {_vm.accountCode=$$v},expression:"accountCode"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"*Descrição","readonly":_vm.justVisible,"outlined":"","required":"","rules":[
              () => !!_vm.description || 'Campo Obrigatório',
              () =>
                (!!_vm.description && _vm.description.length >= 2) ||
                'Campo tem que ser maior do que 2',
            ]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.justVisible,"items":_vm.generateMovementList,"label":"*Gera Movimentação","outlined":"","rules":[() => !!_vm.generateMovement || 'Campo Obrigatório']},model:{value:(_vm.generateMovement),callback:function ($$v) {_vm.generateMovement=$$v},expression:"generateMovement"}})],1),_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.justVisible,"items":_vm.accountTypeList,"label":"*Tipo Contabil","outlined":"","rules":[() => !!_vm.accountType || 'Campo Obrigatório']},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}})],1),_c('v-col',[_c('v-select',{attrs:{"readonly":_vm.justVisible,"items":_vm.planTypeList,"label":"*Tipo de Plano","outlined":"","rules":[() => !!_vm.planType || 'Campo Obrigatório']},model:{value:(_vm.planType),callback:function ($$v) {_vm.planType=$$v},expression:"planType"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":() => {
                _vm.changeDialog();
              }}},[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" Cancelar")],1),(!_vm.justVisible)?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":() => {
                _vm.submit();
              }}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Salvar")],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }