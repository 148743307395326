const GET_ALL_MOVESTOCK = 'GET_ALL_MOVESTOCK'
const UPDATE_ALL_MOVESTOCK = 'UPDATE_ALL_MOVESTOCK'
const DESTROY_ALL_MOVESTOCK = 'DESTROY_ALL_MOVESTOCK'
const ADD_ALL_MOVESTOCK = 'ADD_ALL_MOVESTOCK'
const GET_FILTER_MOVESTOCK = 'GET_FILTER_MOVESTOCK'
const GET_ALL_MOVESTOCK_BY_ENTERPRISE = 'GET_ALL_MOVESTOCK_BY_ENTERPRISE'
const GET_AUTOCOMPLETE_MOVESTOCK_BY_ENTERPRISE = 'GET_AUTOCOMPLETE_MOVESTOCK_BY_ENTERPRISE'
const GET_AUTOCOMPLETE_MOVESTOCK_BY_DATE = 'GET_AUTOCOMPLETE_MOVESTOCK_BY_DATE'

module.exports = {
    GET_ALL_MOVESTOCK,
    UPDATE_ALL_MOVESTOCK,
    DESTROY_ALL_MOVESTOCK,
    ADD_ALL_MOVESTOCK,
    GET_FILTER_MOVESTOCK,
    GET_ALL_MOVESTOCK_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_MOVESTOCK_BY_ENTERPRISE,
    GET_AUTOCOMPLETE_MOVESTOCK_BY_DATE,
}