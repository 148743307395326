<template>
  <Container>
    <!-- ok -->
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" ref="CardTitle" />
      </v-card-text>
      <v-card-text>
        <v-text-field 
        v-model="searchInput" 
        label="Pesquisar"
        outlined
        append-icon="mdi-magnify"
        @keyup.enter="setAutocomplete()"
        >
        </v-text-field>
      </v-card-text>
      <Table
        v-if="permission.Visualizar"
        :component="this"
        :getters="getteComputed"
        :destroy_object="destroy_object"
        :headers="headers"
        :change_addDialog="change_addDialog"
        :visibleItem="visibleItem"
        :change_downloadDialog="change_downloadDialog"
      />
      <ModalBillsPay v-if="addDialog" :component="this" />
      <ModalDownload
        v-if="downloadialog"
        :component="this"
        download="DOWNLOAD_BILLSPAY"
      />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>


<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/bills_pay/CardTitle/";
import Table from "../components/bills_pay/Table/index";
import ModalBillsPay from "../components/bills_pay/AddDialog/";
import ModalDownload from "../components/bills_pay/DownloadBills/";
import loadScreen from "../components/load/";

import {
  GET_ALL_BILLSPAY_BY_STATUS,
  DESTROY_BILLSPAY,
  UPDATE_BILLSPAY,
  AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY,
} from "../store/actions/bills_pay";
import { GET_ALL_ACCOUNT_PLAN } from "../store/actions/account_plan";
import AlertApi from "./../components/alert_api/alert_api";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    searchInput: String(),
    search: "",
    getter: "getBillPays",
    addDialog: false,
    downloadialog: false,
    objectToUpdate: {},
    itemToDownload: {},
    permission: Object(),
    title: "Contas a Pagar",
    headers: [
      { text: "ID", value: "id" },
      { text: "Status", value: "status" },
      { text: "Tipo Pessoa", value: "provider.type_people" },
      { text: "Codigo do Fornecedor", value: "provider_id" },
      { text: "Nome do Fornecedor", value: "provider.name" },
      { text: "Tipo do titulo", value: "type_title" },
      { text: "Data Emissão", value: "issuance_date" },
      { text: "Data Vencimento", value: "issuance_due" },
      { text: "Numero do titulo", value: "number_title" },
      { text: "Valor do titulo", value: "value_title" },
      { text: "Valor liquido", value: "value_net" },
      { text: "Saldo titulo", value: "title_balance" },
      { text: "Ações", value: "actions" },
    ],
    enterpriseId: Number(),
  }),
  computed: {
    ...mapGetters(["getBillPays", "getFinanceiroLinks", "getAccount_Plan"]),
    getteComputed: function () {
      let data = this.getBillPays;
      if (data.data) {
        data.data.map((r) => {
          r.peopleComputed = r.provider.people.legalpeople
            ? r.provider.people.legalpeople
            : r.provider.people.physicalpeople;
          return r;
        });
      }
      return data;
    },
  },
  methods: {
    async destroy_object(item) {
      const bank = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(DESTROY_BILLSPAY, bank);
      } catch (error) {
        alert(error);
      }
    },
    change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = item;
    },
    visibleItem(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = {item: item, justVisible: true};
    },
    change_downloadDialog(item) {
      this.downloadialog = !this.downloadialog;
      this.itemToDownload = item;
    },
    async update_object(item) {
      const bank = {
        id: item.id,
      };
      try {
        await this.$store.dispatch(UPDATE_BILLSPAY, bank);
      } catch (error) {
        alert(error);
      }
    },
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        enterprise_id: this.enterpriseId
      };
      await this.$store.dispatch(GET_ALL_BILLSPAY_BY_STATUS, value);
    },
    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
    },
    async filterBillsToPay(page = 1){
        let payload = {
          search: this.search,
          page: page,
          limit:10,
          paginate:true,
          enterprise_id: this.enterpriseId
        }
        await this.$store.dispatch(AUTO_COMPLETE_BY_ENTERPRISE_BILLSPAY, payload)
    },
    setAutocomplete() {
      this.search = this.searchInput
    },

  },
  async created() {
    this.GetAllNoPage();
  },
  mounted() {
    let name = document.URL.split("/").pop();
    this.permission = this.getFinanceiroLinks.getPermissions(name);
  },

  watch: {
    enterpriseId: function(val) {
      this.GetAll()
    },

    search: function(val) {
      if(val.length >= 2) {
        this.filterBillsToPay()
      }
    },

    searchInput: function(val) {
      if(val.length == 0) {
        this.GetAll()
        this.search = ""
      }
    }
  },

  components: {
    Container,
    CardTitle,
    Table,
    loadScreen,
    ModalBillsPay,
    ModalDownload,
    AlertApi,
  },
};
</script>

