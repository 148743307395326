<template>
  <div>
    <v-form v-model="component.accountingForm">
      <v-col>
        <v-select
          label="Centro de Custo"
          v-model="component.centerCostId"
          :readonly="component.justVisible"
          :items="this.costCenters"
          item-value="id"
          item-text="name_cost_center"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
        :readonly="component.justVisible"
          label="*Plano de Contas"
          v-model="component.plan"
          :items="component.accounting_plan"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogAccountPlan"
          item-value="id"
          item-text="description"
          outlined
          :rules="[() => !!component.plan || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-form>
    <AccountPlanDialog/>
  </div>
</template>

<script>
import { mdiPlusCircle } from '@mdi/js';
import AccountPlanDialog from '../../../account_plans/AddDialog'
import { GET_ALL_COST_CENTER } from "../../../../store/actions/cost_center"
import { mapGetters } from "vuex";
export default {
  components:{AccountPlanDialog},
  props: {
    component: Object,
  },
  data() {
    return {
      icons:[mdiPlusCircle],
      valid: true,
      costCenters: Array()
    };
  },
  computed:{
    ...mapGetters(["getCostCenter"]),
  },
  methods:{
    showDialogAccountPlan(){
      this.$store.dispatch("setShowDialogRegisterAccountPlan",true)
    },
  getAllCostCenter() {
      const payload = {
        page: 1,
        limit: null,
        paginate: false,
      }
      this.$store.dispatch(GET_ALL_COST_CENTER, payload)
    }
  },

  created() {
    this.getAllCostCenter()
  },

  watch: {
    valid: function () {
      if (this.valid == true) {
        this.component.Validation_form.tab_Accounting = this.valid;
      }
    },
    
    getCostCenter: function(val) {
      this.costCenters = Object.values(val);
    }
  },
};
</script>

<style>
</style>