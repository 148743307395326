const ADD_COST_CENTER = "ADD_COST_CENTER";
const GET_ALL_COST_CENTER = "GET_ALL_COST_CENTER";
const AUTOCOMPLETE_COST_CENTER = "AUTOCOMPLETE_COST_CENTER";
const UPDATE_COST_CENTER = "UPDATE_COST_CENTER";
const DELETE_COST_CENTER = "DELETE_COST_CENTER";

module.exports = {
    ADD_COST_CENTER,
    GET_ALL_COST_CENTER,
    AUTOCOMPLETE_COST_CENTER,
    UPDATE_COST_CENTER,
    DELETE_COST_CENTER,
}
