const headers = {

    isPhisical: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "physicalpeople.name" },
        { text: "CPF", value: "physicalpeople.cpf" },
    ],

    
    isLegal: [
        { text: "Codigo", value: "id" },
        { text: "Nome", value: "legalpeople.socialName" },
        { text: "Nome Fantasia", value: "legalpeople.fantasyName" },
        { text: "CNPJ", value: "legalpeople.cnpj" },
    ]

};

export { headers };
