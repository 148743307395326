<template>
    <Container>
      <v-card elevation="5">
        <v-card-text>
          <CardTitle :component="this" />
        </v-card-text>
        <v-card-text>
          <v-text-field
            class="mx-4"
            label="Pesquisar"
            outlined
            v-model="searchInput"
            append-icon="mdi-magnify"
            @keyup.enter="setAutocomplete()"
          ></v-text-field>
        </v-card-text>
        <Table :component="this" />
      </v-card>
      <AddDialog :component="this" v-if="registerDialog" />
      <ErrorDialog :component="this" v-if="errorDialog" />

      <AlertApi />
      <loadScreen />
    </Container>
  </template>
  
  <script>
  import Container from "../components/Utils/Container/Container";
  import CardTitle from "../components/CostCenter/CardTitle/index.vue";
  import Table from "../components/CostCenter/Table/index.vue";
  import AddDialog from "../components/CostCenter/AddDialog/index.vue";
  import ErrorDialog from "../components/CostCenter/ErrorDialog/index.vue";
  import loadScreen from "../components/load/";
  import AlertApi from "../components/alert_api/alert_api";
  
  import { GET_ALL_COST_CENTER, AUTOCOMPLETE_COST_CENTER, DELETE_COST_CENTER, ADD_COST_CENTER, UPDATE_COST_CENTER } from "../store/actions/cost_center"
  import { mapGetters } from "vuex";
  
  export default {
    components: {
      Container,
      CardTitle,
      Table,
      AddDialog,
      ErrorDialog,
      AlertApi,
      loadScreen,
    },
  
    data: () => ({
      search: String(),
      searchInput: String(),
      registerDialog: Boolean(),
      costCenterToUpdate: Object(),
      id: null,
      justView: Boolean(),
      errorDialog: Boolean(),
      errorMessage: String(),
    }),
  
    methods: {
        GetAll(page = 1) {
            const payload = {
              page: page,
              limit: 10,
              paginate: true
            }
            this.$store.dispatch(GET_ALL_COST_CENTER, payload)
        },

        setAutocomplete() {
            this.search = this.searchInput
        },

        getAutocomplete(page = 1) {
          const payload = {
              search: this.search,
              page: page,
              limit: 10,
              paginate: true
            }
            this.$store.dispatch(AUTOCOMPLETE_COST_CENTER, payload)
        },

        loadItemToUpdate(costCenter) {
          this.costCenterToUpdate = costCenter
          this.id = costCenter.id
          this.setRegisterDialog()
        },

        loadItemToView(costCenter) {
          this.justView = true
          this.costCenterToUpdate = costCenter
          this.id = costCenter.id
          this.setRegisterDialog()
        },

        async create(payload) {
          await this.$store.dispatch(ADD_COST_CENTER, payload).then((response) => {
            if (typeof response === 'string') {
              this.errorMessage = response
              this.setErrorDialog()
            } else {
              this.setRegisterDialog()
            }
        });
        },

        async update(payload) {
          await this.$store.dispatch(UPDATE_COST_CENTER, payload).then((response) => {
            if (typeof response === 'string') {
              this.errorMessage = response
              this.setErrorDialog()
            } else {
              this.setRegisterDialog()
            }
          });
        },

        async deleteItem(costCenter) {
          const payload = {
            id: costCenter.id
          }
          
          this.$store.dispatch(DELETE_COST_CENTER, payload).then((response) => {
            if (typeof response === 'string') {
              this.errorMessage = response
              this.setErrorDialog()
            } else {
              this.GetAll()
            }
        });
        },

        setRegisterDialog() {
          if(this.registerDialog) {
            this.GetAll()
            this.justView = false
          }
          this.registerDialog = !this.registerDialog
        },

        setErrorDialog() {
          this.errorDialog = !this.errorDialog
        }
    },

    created() {
      this.GetAll()
    },
  
    watch: {
        search: function(val) {
            if(val.length >= 2) {
                this.getAutocomplete()
            }
        },

        searchInput: function(val) {
            if(val.length == 0) {
                this.GetAll()
                this.search = ""
            }
        }
    },
  };
  </script>