<template>
<div> 
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select
          outlined 
          label="Status do Título *" 
          :items="status" 
          v-model="component.report.status"
          :rules="[
            () => !!component.report.status || 'Campo Obrigatorio'
          ]"
        ></v-select>
      </v-col>

      <v-col>
        <v-text-field
          label="Clientes"
          append-icon="mdi-magnify"
          @click:append="dialog = !dialog"
          v-model="component.report.client_id"
          outlined
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" xl="2" lg="2">
        <v-text-field
          outlined :label="labelInitialDate"
          type="date" 
          v-model="component.report.date_initial"
          :rules="[
              () => !!component.report.date_initial || 'Campo Obrigatorio'
          ]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="2" md="2" xl="2" lg="2">
        <v-text-field  
          outlined :label="labelFinalDate"
          type="date" 
          v-model="component.report.date_final"
          :rules="[
              () => !!component.report.date_final || 'Campo Obrigatorio'
          ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Tipo de Título" 
          v-model="component.report.type_title" 
          :items="titulo"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Plano de Contas" 
          :items="component.account" 
          item-text="description" 
          item-value="id" 
          v-model="component.report.account_plans_id"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-select 
          outlined 
          label="Centro de Custo" 
          :items="component.costCenters" 
          item-text="name_cost_center" 
          item-value="id" 
          v-model="component.report.center_cost"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
  <v-dialog max-width="800" persistent v-model="dialog">
      <v-card>
        <v-container>
          <v-card-title>Clientes</v-card-title>
          <v-switch
            v-model="isFormalPeople"
            label="Pessoa Jurídica"
            color="primary"
          >
          </v-switch>
          <v-text-field
            outlined
            label="Pesquisar"
            @keyup.enter="setAutocompleteClient()"
            append-icon="mdi-magnify"
            v-model="searchInput"
          ></v-text-field>
          <v-divider></v-divider>
          <v-data-table
            :headers="atual.header"
            :items="atual.getter"
            hide-default-footer
            @click:row="rowClicked"
            no-data-text="Sem registros"
          ></v-data-table>
          <v-divider></v-divider>
          <v-pagination class="mb-6" v-model="atualCurrent" :length="atualTotal" :total-visible="10" color="success"></v-pagination>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="success" @click="dialog = !dialog">Fechar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
</div>
</template>

<script>

import { headers } from "./script";
import { mapGetters } from "vuex";
import { GET_ALL_CLIENT_BY_ENTERPRISE, GET_FILTER_CLIENT } from "../../../../store/actions/client";

export default {
  props:{
    component: Object
  },


  data() {
    return {
      titulo: ["Boleto", "Depósito Bancário", "Dinheiro"],
      status: ["Aberto", "Baixado"],
      dialog: false,
      headers,
      search: "",
      searchInput: "",
      getter: Array,
      atual: {
        header: Array(),
        getter: Array(),
      },
      isFormalPeople: false,
      pagPhysicalTotal: Number(),
      pagPhysicalCurrent: Number(),
      pagLegalTotal: Number(),
      pagLegalCurrent: Number(),
      atualCurrent: Number(),
      atualTotal: Number(),
    }
  },

  methods:{
    
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    rowClicked(row) {
      
      if (row.type_people == "PhysicalPeople") {
        this.component.report.client_id = row.id;
      } else {
        this.component.report.client_id = row.id;
      }

      this.dialog = false;
    },

    pagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },

    pagLegal(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },

    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];
          this.atual.getter = isLegal
            ? this.getLegalClients
            : this.getPhysicalClients;
      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },

    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT_BY_ENTERPRISE, {
        type_people: "PhysicalPeople",
        page,
        limit: 10,
        paginate: true,
        enterprise_id: this.component.selectValue.id,
      });
    },

    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT_BY_ENTERPRISE, {
        type_people: "LegalPeople",
        page,
        limit: 10,
        paginate: true,
        enterprise_id: this.component.selectValue.id,
      });
    },

    setAutocompleteClient() {
      this.search = this.searchInput
    },

    getAutocompleteClientPhysical(page = 1) {
      const payload = {
          page: page,
          limit: 10,
          paginate: true,
          type_people: "PhysicalPeople",
          search: this.search,
          enterprise_id: this.component.selectValue.id,
      }
      this.$store.dispatch(GET_FILTER_CLIENT, payload)
    },

    getAutocompleteClientLegal(page = 1) {
      const payload = {
          page: page,
          limit: 10,
          paginate: true,
          type_people: "LegalPeople",
          search: this.search,
          enterprise_id: this.component.selectValue.id,
      }
      this.$store.dispatch(GET_FILTER_CLIENT, payload)
    }
  },

  created() {
    this.GetAllPhysical();
    this.GetAllLegal();
  },

  computed: {
    labelInitialDate() {
      if(this.component.report.status === null) {
        return 'Data Inicial *'
      }

      if(this.component.report.status === 'Aberto') {
        return 'Data de Emissão Inicial *';
      }

      if(this.component.report.status === 'Baixado') {
        return 'Data da Baixa Inicial *';
      }
    },

    labelFinalDate() {
      if(this.component.report.status === null) {
        return 'Data Final *'
      }

      if(this.component.report.status === 'Aberto') {
        return 'Data de Emissão Final *';
      }

      if(this.component.report.status === 'Baixado') {
        return 'Data da Baixa Final *';
      }
    },

    ...mapGetters(["getPhysicalClients", "getLegalClients", "getClientPhysicalAuto", "getClientLegalAuto"]),
  },

  mounted() {
    this.isLegal();
    this.GetAllPhysical();
    this.GetAllLegal();
    this.$watch(
      () => this.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },

  watch: {

    atualCurrent: function (val) {
      if (this.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },

    getPhysicalClients: function (val) {
      this.pagPhysicalTotal = val[0].page.last_page;
      this.pagPhysicalCurrent = val[0].page.current_page;
      this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
    },

    getLegalClients: function (val) {
      this.pagLegalTotal = val[0].page.last_page;
      this.pagLegalCurrent = val[0].page.current_page;
      this.pagLegal(this.pagLegalTotal, this.pagLegalCurrent);
    },

    getClientPhysicalAuto: function (val) {
      this.pagPhysicalTotal = val[0].page.last_page;
      this.pagPhysicalCurrent = val[0].page.current_page;
      this.pagPhysical(this.pagPhysicalTotal, this.pagPhysicalCurrent);
    },

    getClientLegalAuto: function (val) {
      this.pagLegalTotal = val[0].page.last_page;
      this.pagLegalCurrent = val[0].page.current_page;
      this.pagLegal(this.pagLegalTotal, this.pagLegalCurrent);
    },

    search: function(val) {
      if(val.length >= 2) {
          this.getAutocompleteClientLegal()
          this.getAutocompleteClientPhysical()
      }
    },

    "component.selectValue":function(){
      this.GetAllPhysical();
      this.GetAllLegal();
    },

    searchInput: function(val) {
      if(val.length == 0) {
        this.GetAllPhysical();
        this.GetAllLegal();
        this.search = ""
      }
    },
  }
};
</script>

<style>
</style>