<template>
  <v-container>
    <v-card-title style="font-size: 25px">Filtros</v-card-title>
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field v-model="initial_date_sales" type="date" label="*Data Inicial" outlined dense />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field v-model="final_date_sales" type="date" label="*Data Final" outlined dense />
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete :items="listEnterprise" item-text="fantasyName" v-model="enterpriseSelected" return-object chips
          label="*Empresas" outlined dense />
      </v-col>
      <v-col cols="12" md="1">
        <v-btn @click="submit" class="success" style="width: 100%;"><v-icon class="mr-1">mdi-magnify</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { DATA_SALES } from "../../../store/actions/dashboard_charts";
import {
  GET_ALL_ENTERPRISE,
  SELECTED_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { todayDate } from "../../Utils/global_functions/today_date";

export default {
  components: {},
  props: {
    component: Object,
  },
  computed: {
    ...mapGetters(["getEnterprises", "getSelectedEnterprise", "getReportChartSales"]),
  },
  data: () => ({
    initial_date_sales: null,
    final_date_sales: null,
    listEnterprise: Array(),
    enterpriseSelected: Object(),
  }),
  methods: {
    settingDate() {
      const today = new Date();
      this.initial_date_sales = this.formatDate(today);
      this.final_date_sales = this.formatDate(today);

    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    getEnterprise(page = 1) {
      const payload = {
        page,
        paginate: true,
        limit: 10,
      };

      this.$store.dispatch(GET_ALL_ENTERPRISE, payload);
    },
    async submit() {
      const payload = {
        initial_date_sales: this.initial_date_sales,
        final_date_sales: this.final_date_sales,
        enterprise_id: this.enterpriseSelected.id,
        filter: true,
      };
      await this.$store.dispatch(DATA_SALES, payload).then((response) => {
        this.component.salesDashboard = response
        this.component.value_total_sales = this.component.salesDashboard[0].valuesTotalSales[0].venda_total.toFixed(2)
        this.component.value_discount = this.component.salesDashboard[1].discountPerStore[0].total_discount.toFixed(2)
        this.component.average_ticket = this.component.salesDashboard[1].averageTicket.toFixed(2)
        this.component.value_exchange = this.component.salesDashboard[1].exchangeCredit.toFixed(2)

      })
    },
  },
  watch: {
    getEnterprises: function (val) {
      this.listEnterprise = val.data;
      this.enterpriseSelected = val.data[0];
      this.submit()
    },

    enterpriseSelected: function (val) {

      this.$store.dispatch(SELECTED_ENTERPRISE, val);
    },
  },

  mounted() {
    this.settingDate()
    this.getEnterprise()
  }
};
</script>

<style></style>