<template>
  <v-container>
    <div>
      <v-form :disabled="isDisable" ref="form" v-model="valid">
        <v-row>
          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-text-field
              @keyup.enter="choose_product()"
              outlined
              label="Produto"
              v-model="description.description_item"
              return-object
              :rules="rules"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('productdialog')"
              append-icon="mdi-magnify"
              @click:append="() => openDialogModalSearchProduct()"
            ></v-text-field
          ></v-col>

          <v-col cols="12" lg="1" xl="6" md="5" sm="6">
            <v-text-field
              outlined
              type="number"
              label="Qtd"
              :rules="rules"
              v-model="product.amount_add"
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-text-field
              outlined
              type="number"
              :rules="rules"
              label="Val. Venda"
              v-model="product.value_sale"
            ></v-text-field
          ></v-col>
          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              outlined
              label="Alíquota"
              :rules="rules"
              item-text="aliquot"
              :items="aliquotItems"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('aliquotlialog')"
              v-model="descriptionAliquot"
              return-object
              @click:append="
                () => {
                  changeDialogAliquot();
                }
              "
              append-icon="mdi-magnify"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              label="CFOP"
              item-text="code"
              :items="cfopItems"
              :rules="rules"
              outlined
              v-model="descriptionCFOP"
              return-object
              append-icon="mdi-magnify"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('cfopdialog')"
              @click:append="() => openDialogModalSearchCfop()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" lg="2" xl="6" md="5" sm="6">
            <v-autocomplete
              v-if="component.validCST"
              v-model="cst_csosn"
              item-text="description"
              :rules="rules"
              outlined
              :items="csosnItems"
              return-object
              label="CSOSN"
            ></v-autocomplete>

            <v-autocomplete
              v-else
              v-model="cst_csosn"
              item-text="description"
              :rules="rules"
              :items="cstItems"
              outlined
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('cstdialog')"
              return-object
              label="CST"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              outlined
              v-model="descriptionPis"
              item-text="description"
              item-value="id"
              :items="pis_cofis"
              label="CST/PIS"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('cstpisdialog')"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              v-model="descriptionCofins"
              item-text="description"
              item-value="id"
              :items="pis_cofis"
              label="CST/COFINS"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="openDialogs('cstcofinsdialog')"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              v-model="product.aliquot_ipi_product"
              label="Valor de IPI"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="1" xl="1" md="2" sm="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  :disabled="!valid"
                  elevation="5"
                  v-bind="attrs"
                  @click="addProduct()"
                  v-on="on"
                  class="success mt-2"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span>Adicionar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
        <v-divider></v-divider>
        <TableRevenues
          :component="component"
          :actionDelete="deleteItens"
          :getters="getRevenusItens"
          :actionEdit="changeDialogEdit"
        />
        <AddProduct
          v-if="dialogModalSearchProduct"
          :changeDialog="openDialogModalSearchProduct"
          :component="this"
        />
        <editItem
          v-if="dialogEdit"
          :dialog="dialogEdit"
          :disabledField="component.validCST"
          :changeDialog="changeDialogEdit"
          :submit="updateItens"
          :component="this"
        />

        <ProductDialog :out_of_register="true" />
        <AliquotDialog />
        <CFOPDialog />
        <CSTDialog />
        <PISDialog />
        <COFINSDialog />
        <AlertApi />
      
    </div>
  </v-container>
</template>

<script>
import AddProduct from "./DialogProduct/";
import TableRevenues from "../../Table/TableRevenues/index.vue";
import editItem from "../../AddDialog/EditItensNote/";

import ProductDialog from "../../../products/AddDialog/index.vue";
import AliquotDialog from "../../../aliquot/AddDialog";
import CFOPDialog from "../../../cfop/AddDialog";
import CSTDialog from "../../../cst/AddDialog/index.vue";
import PISDialog from "../../../pis_cofins/AddDialog";
import COFINSDialog from "../../../pis_cofins/AddDialog";

import {
  ADD_REVENUES_ITENS,
  DESTROY_REVENUES_ITENS,
  UPDATE_REVENUES_ITENS,
} from "../../../../store/actions/revenuesitens";
import { GET_PRODUCT_BY_ID } from "../../../../store/actions/product";
import { GET_ALL_CFOP } from "../../../../store/actions/cfop";
import { GET_ALL_ALIQUOT } from "../../../../store/actions/aliquot";
import { GET_ALL_CST } from "../../../../store/actions/cst";
import { GET_ALL_CSOSN } from "../../../../store/actions/csosn";
import { GET_ALL_PIS_COFINS } from "../../../../store/actions/pis_cofins";
import AlertApi from "../../../alert_api/alert_api.vue"
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
    isDisable: Boolean,
  },
  data: () => ({
    pisOrConfis: 0,
    enterprise: Array(),
    dialogModalSearchProduct: Boolean(),
    dialogModalSearchCst: Boolean(),
    loadingAddProduct: Boolean(),
    dialogEdit: Boolean(),
    dialog_aliquot: Boolean(),

    product: Object(),
    description: Object(),
    descriptionAliquot: Object(),
    descriptionCFOP: Object(),
    descriptionPis: Object(),
    descriptionCofins: Object(),
    selectedItem: Object(),
    cst_csosn: Object(),
    ObjectToUpdate: Object(),

    aliquotItems: Array(),
    cfopItems: Array(),
    cstItems: Array(),
    csosnItems: Array(),
    pis_cofis: Array(),
    rules: [(v) => !!v || "Campo Obrigatório"],
    valid: Boolean(),
  }),
  components: {
    TableRevenues,
    AddProduct,
    editItem,
    AlertApi,
    ProductDialog,
    AliquotDialog,
    CFOPDialog,
    CSTDialog,
    PISDialog,
    COFINSDialog,
  },
  computed: {
    ...mapGetters([
      "getAliquot",
      "getCfop",
      "getRevenusItens",
      "getCsosn",
      "getCst",
      "getPisCofins",
      "getProduct",
      "getEnterpriseObject",
      "getAliquotToAdd",
      "getCfopToAdd",
    ]),
  },
  methods: {
    openDialogs(dialog) {
      if (dialog == "productdialog") {
        this.$store.dispatch("setShowDialogRegisterProduct", true);
      } else if (dialog == "aliquotlialog") {
        this.$store.dispatch("setShowDialogRegisterAliquot", true);
      } else if (dialog == "cfopdialog") {
        this.$store.dispatch("setShowDialogRegisterCFOP", true);
      } else if (dialog == "cstdialog") {
        this.$store.dispatch("setShowDialogRegisterCST", true);
      } else if (dialog == "cstpisdialog") {
        this.pisOrConfis = 1;
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      } else if (dialog == "cstcofinsdialog") {
        this.pisOrConfis = 2;
        this.$store.dispatch("setShowDialogRegisterPISCOFINS", true);
      }
    },
    async choose_product() { 
      const payload = {
        id: this.description.description_item,
        enterprise_id: this.enterprise[0].id
      }
      await this.$store.dispatch(GET_PRODUCT_BY_ID, payload).then((response) =>{
        this.description = response
      });
    },
    async addProduct() {
 
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
       let product = { ...this.product };
        if (this.component.validCST) {
          product.csosn_id = this.cst_csosn.id;
          product.cst_id = null;
        } else {
          product.cst_id = this.cst_csosn.id;
          product.csosn_id = null;
        }
        product.id = this.product.id;
        product.amount = this.product.amount_add;
        product.description_cfop_code = this.product.description_cfop_code;
        product.aliquot_id = this.product.aliquot_id;
        product.aliquot = this.description.aliquot.aliquot;
        product.product_id = this.product.id;
        product.value_sale = this.product.value_sale;
        product.enterprise_id = this.description.enterprise_id;
        product.ncm_code = this.description.ncm.code;
        product.enterprises = this.enterprise;
        product.aliquot_ipi_product = this.product.aliquot_ipi_product;
        product.cst_cofins_id = this.product.cst_cofins_id;
        product.cst_pis_id = this.product.cst_pis_id;
        product.cst_pis_code = this.product.cst_pis_code;
        product.cst_confis_code = this.product.cst_confis_code;
        product.tax_operation_types_id = this.component.form.tax_operation_types_id;
        product.aliquot_pis = this.enterprise[0].aliquot_pis;
        product.aliquot_cofins = this.enterprise[0].aliquot_cofins;
        product.aliquot_icms_product = this.product.aliquot_icms_product;

        product.code_item = 0;
        product.description_unit = this.product.description_unit;
        product.description_product = this.product.description_item;
        product.cst_code_ipi; //= this.description.cst_ipi.code;
        product.cst_confis_id = this.product.cst_cofins_id;
        //product.product_id = this.description.product_id;
        product.cfop_id = this.description.cfop_id;
        product.csosn_id = this.description.csosn_id;
        product.cst_id = this.description.cst_id;
        product.revenues_id = null;
        product.cst_code_pis = this.description.cst_pis.code;
        product.cst_code_confis = this.description.cst_confis.code;
        product.code_cfop = this.description.cfop.code;
        product.code_csosn = this.description.csons.code;
        product.code_cst = this.description.cst.code;
        product.unit_id = this.description.unit_id;
        product.venda_sale = 0;
        product.value_subtotal_product = 0;
        product.value_icms_st_product = 0;
        product.value_accounting_product = 0;
        product.value_base_ipi_product = 0;
        product.value_base_icms_st_product = 0;
        product.value_ipi_product = 0;
        product.value_icms_product = 0;
        product.value_icms_free_product = 0;
        product.value_icms_outher_product = 0;
        product.value_ipi_free_product = 0;
        product.value_ipi_outher_product = 0;
        product.value_descont_product = 0;
        product.value_base_icms_product = 0;
        product.value_base_pis_product = 0;
        product.value_pis_product = 0;
        product.value_base_cofins_product = 0;
        product.value_cofins_product = 0;
        product.value_base_pis_st_product = 0;
        product.value_pis_st_product = 0;
        product.value_base_cofins_st_product = 0;
        product.value_cofins_st_product = 0;
        product.value_other_pis_product = 0;
        product.value_other_cofins_product = 0;
        product.value_free_cofins_product = 0;
        product.value_free_pis_product = 0;
        product.value_book = 0;

        try {
          this.$store.dispatch(ADD_REVENUES_ITENS, product).then(() => {
            this.$refs.form.reset()
          });
        } catch (e) {
          alert(e);
        }
      }
    },

    changeDialogEdit(item) {
      if (!this.isDisable) {
        if (!this.dialogEdit) {
          this.ObjectToUpdate = { ...item };
          this.ObjectToUpdate.tax_operation_types_id =
            this.component.form.tax_operation_types_id;
          this.ObjectToUpdate.enterprise_id = this.component.enterpriseId;
        }
        this.dialogEdit = !this.dialogEdit;
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    async updateItens(val) {
      let product = {
        id: val.id,
        tableId: val.tableId,
        amount_add: parseFloat(val.amount),
        value_sale: val.cust,
        description_cst_code: val.cst_csosn.code,
        cfop_id: val.cfop.id,
        description_cfop_code: val.cfop.code,
        aliquot_id: val.aliquot.id,
        aliquot: val.aliquot.aliquot,
        product_id_add: val.product_id,
        tax_operation_types_id: val.tax_operation_types_id,
        enterprise_id: val.enterprise_id,
        value_aliquot_ipi: val.ipi,
        cst_pis_id: val.cst_pis.id,
        cst_pis_code: val.cst_pis.code,
        cst_cofins_id: val.cst_cofins.id,
        cst_confis_code: val.cst_cofins.code,

        cst_id: Number(),
        csosn_id: Number(),
      };
      if (product.description_cst_code < 100) {
        product.cst_id = val.cst_csosn.id;
        product.csosn_id = null;
      } else {
        product.csosn_id = val.cst_csosn.id;
        product.cst_id = null;
      }

      await this.$store.dispatch(UPDATE_REVENUES_ITENS, product).then(() => {
        this.changeDialogEdit();
      });
    },

    async GetAliquot() {
      await this.$store.dispatch(GET_ALL_ALIQUOT, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCfop() {
      await this.$store.dispatch(GET_ALL_CFOP, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCst() {
      await this.$store.dispatch(GET_ALL_CST, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetCsosn() {
      await this.$store.dispatch(GET_ALL_CSOSN, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetPisCofins() {
      await this.$store.dispatch(GET_ALL_PIS_COFINS, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    openDialogModalSearchProduct() {
      this.dialogModalSearchProduct = !this.dialogModalSearchProduct;
    },
    openDialogModalSearchCst() {
      this.dialogModalSearchCst = !this.dialogModalSearchCst;
    },

    deleteItens(item) {
      if (!this.isDisable) {
        try {
          this.$store.dispatch(DESTROY_REVENUES_ITENS, item.tableId);
        } catch (error) {
          alert(error);
        }
      } else {
        alert("Não é permitido na ação de Informações.");
      }
    },
    clearItens() {
      try {
        this.$store.dispatch(DESTROY_REVENUES_ITENS, "all");
      } catch (error) {
        alert(error);
      }
    },
    valid_cst_csosn(val) {
      if (this.component.validCST) {
        this.cst_csosn = val.csons;
      } else {
        this.cst_csosn = val.cst;
      }
    },
  },

  watch: {
    description: function (val) {
      if (val.id !== undefined) {
        this.descriptionCFOP = val.cfop;
        this.descriptionAliquot = val.aliquot;
        this.descriptionPis = val.cst_pis;
        this.descriptionCofins = val.cst_confis;

        this.product.id = val.id;
        this.product.product_id_add = val.id;
        (this.product.description_item = val.description_item),
          (this.product.ncm_id = val.ncm.id),
          (this.product.ncm_code = val.ncm.ncm_code),
          (this.product.unit_id = val.unit.id),
          (this.product.description_unit = val.unit.sigla),
          (this.product.value_sale = val.sale_price);
        this.product.cfop_id = val.cfop.id;
        this.product.cfop_code = val.cfop.code;

        this.product.aliquot_id = val.aliquot.id;
        this.product.aliquot_code = val.aliquot.aliquot;

        this.product.cst_id = val.cst.id;
        this.product.cst_code = val.cst.code;

        // this.product.csosn_id = val.csosn.id;
        // this.product.csosn_code = val.csosn.code;
        this.product.value_products = 0;
        this.product.aliquot_icms_product = val.aliquot.aliquot;

        this.product.value_icms_product = 0;
        this.product.base_icms_product = 0;
        this.product.value_accounting_product = 0;
        this.product.value_free_icms_product = 0;
        this.product.value_icms_outher_product = 0;
        this.product.value_base_icms_st_product = 0;
        this.product.value_icms_st_product = 0;
        this.product.aliquot_ipi_product = val.ipi;
        this.product.value_ipi_product = 0;
        this.product.value_base_ipi_product = 0;
        this.product.value_ipi_outher_product = 0;
        this.product.value_ipi_free_product = 0;

        this.product.cst_pis_id = val.cst_pis.id;
        this.product.cst_pis_code = val.cst_pis.code;

        this.product.cst_cofins_id = val.cst_confis.id;
        this.product.cst_cofins_code = val.cst_confis.code;

        this.product.aliquot_pis = val.cst_pis.code;
        this.product.aliquot_cofins = val.cst_confis.code;

        this.product.value_base_pis_product = 0;
        this.product.value_pis_product = 0;
        this.product.value_base_confis_product = 0;
        this.product.value_confis_product = 0;
        this.product.value_base_pis_st_product = 0;
        this.product.value_pis_st_product = 0;
        this.product.value_base_cofins_st_product = 0;
        this.product.value_cofins_st_product = 0;
        this.product.value_other_pis_product = 0;
        this.product.value_other_confis_product = 0;
        this.product.value_free_confis_product = 0;
        this.product.value_free_pis_product = 0;
        // this.product.enterprises = val.enterprises;

        if (val.ipi === null) {
          this.product.aliquot_ipi_product = 0;
        } else {
          this.product.aliquot_ipi_product = val.ipi;
        }
        this.valid_cst_csosn(val);
      }
    },
    descriptionAliquot: function (val) {
      this.product.description_aliquot_code = val.aliquot;
      this.product.aliquot_id = val.id;
    },
    descriptionCFOP: function (val) {
      this.product.description_cfop_code = val.code;
      this.product.cfop_id = val.id;
    },
    descriptionPis: function (val) {
      this.product.cst_pis_code = val.code;
      this.product.cst_pis_id = val.id;
    },
    descriptionCofins: function (val) {
      this.product.cst_cofins_id = val.id;
      this.product.cst_confis_code = val.code;
    },
    getAliquot: function (val) {
      if (!this.dialog_aliquot) {
        this.aliquotItems = Object.values(val);
      }
    },
    getAliquotToAdd: function () {
      this.aliquotItems.push(this.getAliquotToAdd);
      this.descriptionAliquot = this.getAliquotToAdd.aliquot;
    },
    getCfop: function (val) {
      if (!this.dialog_cfop) {
        this.cfopItems = Object.values(val);
      }
    },
    getCfopToAdd: function (value) {
      this.cfopItems.push(value);
      this.descriptionCFOP = value.code;
    },
    getCsosn: function (val) {
      if (this.csosnItems.length == 0) {
        this.csosnItems = Object.values(val);
      } else {
        this.csosnItems.push(val);
        this.cst_csosn = val.code;
      }
    },
    getCst: function (val) {
      this.cstItems = Object.values(val);
    },
    getPisCofins: function (val) {
      if (this.pis_cofis.length == 0) {
        this.pis_cofis = Object.values(val);
      } else {
        if (this.pisOrConfis == 1) {
          this.pis_cofis.push(val);
          this.descriptionPis = val;
        } else if (this.pisOrConfis == 2) {
          this.pis_cofis.push(val);
          this.descriptionCofins = val;
        }
      }
    },
    getProduct: function (val) {
      this.description = { ...val };
      this.description.description_item = val.description_item;
    },
  },

  async created() {
    this.enterprise[0] = { ...this.getEnterpriseObject };
    await this.GetAliquot();
    await this.GetCfop();
    await this.GetPisCofins();
    if (this.component.validCST) {
      this.GetCsosn();
    } else {
      this.GetCst();
    }
  },
  destroyed() {
    this.clearItens();
  },
};
</script>

<style></style>
