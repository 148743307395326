

var headersNFE_NFCE = [
    {text:"NFE", value:"55"},
    {text:"NFCE", value:"65"}
]



module.exports = {
    headersNFE_NFCE
}