var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Pesquisar","outlined":"","append-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setAutocomplete()}},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.itemsExchange,"headers":_vm.Header,"no-data-text":"Sem registro!","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":() => {
              _vm.view_info(item);
            }}},[_vm._v("mdi-information-outline")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
            _vm.print_item(item);
          }}},[_vm._v("mdi-printer")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
            _vm.cancelExchange(item);
          }}},[_vm._v("mdi-cancel")])]}}],null,true)}),_c('v-divider'),_c('v-pagination',{staticClass:"mb-1 mt-10",attrs:{"length":_vm.paginationInformation.last_page},model:{value:(_vm.paginationInformation.current_page),callback:function ($$v) {_vm.$set(_vm.paginationInformation, "current_page", $$v)},expression:"paginationInformation.current_page"}}),(_vm.dialog)?_c('ViewInfo',{attrs:{"component":this,"objectToView":_vm.objectToView}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('svg',{attrs:{"id":"barcode"}})]),(_vm.dialogCancel)?_c('CancelExchangeConfirmationDialog',{attrs:{"component":this}}):_vm._e(),(_vm.taxOperationTypeErrorDialog)?_c('TaxOperationTypeDialog',{attrs:{"component":this}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }