<template>
    <div>
      <v-data-table
        :headers="header"
        :items="results"
        no-data-text="Sem registros"
        hide-default-footer
      >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  component.loadItemToView(item)
                }
              "
            >mdi-eye</v-icon>
          </template>
          <span>Visualizar Este Item</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  component.loadItemToUpdate(item);
                }
              "
            >mdi-pencil</v-icon>
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  component.deleteItem(item);
                }
              "
            >mdi-delete</v-icon>
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      class="mb-3"
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>

  </div>
</template>
  
  <script>
  import { header } from "./script";
  import { mapGetters } from "vuex";
  
  export default {
    props: {
      component: Object,
    },
    data: () => ({
      results: Array(),
      currentItem: Object(),
      total: Number(),
      current: Number(),
      itemSelectedOnClick: Object(),
      header
    }),
    
    computed: {
      ...mapGetters(["getCostCenter"]),
    },

     watch: {
      getCostCenter: function(val) {
        this.results = val.data
        this.current = val.current_page
        this.total = val.last_page
      },

      current: function(val) {
        if(this.component.search.length >= 2) {
          this.component.getAutocomplete(val)
        } else {
          this.component.GetAll(val)
        }
      }
    }
  };
  </script>
  