import axios from "axios";
const {
  ITEM_TO_UPDATE,
  ADD_PJ_TRANSPORTER,
  ADD_PF_TRANSPORTER,
  UPDATE_PJ_TRANSPORTER,
  UPDATE_PF_TRANSPORTER,
  GET_ALL_TRANSPORTER,
  DESTROY_TRANSPORTER,
  GET_AUTOCOMPLETE_TRANSPORTER,
  GET_ALL_TRANSPORTER_TO_SELECT_FIELD,
  GET_ALL_TRANSPORTER_BY_ENTERPRISE
} = require("./actions/transporters.js");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleSubGroup = {
  state: () => ({
    PhisycalTransporter: Array(),
    LegalTransporter: Array(),
    legalAutocomplete: Array(),
    physicalAutocomplete: Array(),
    transporterToAdd: Object(),
    itemToUpdate: Object(),
    isUpdated:false,
    selectTransporter: Array(),
  }),

  mutations: {
    SET_IS_UPDATED: function(state, payload){
      state.isUpdated = payload
    },
    ITEM_TO_UPDATE: function (state, payload) {
      state.itemToUpdate = payload
    },
    GET_ALL_TRANSPORTER_TO_SELECT_FIELD: function (state, payload) {
      state.selectTransporter = payload
    },
    GET_ALL_TRANSPORTER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;
      var indx = 0;
      let physicalpeople = {};
      let legalpeople = {};
      let auxPayload = { ...payload };
      delete auxPayload.data;

      if (payload.type === "PhysicalPeople") {
        while (state.PhisycalTransporter.length) {
          state.PhisycalTransporter.pop();
        }
      } else {
        while (state.LegalTransporter.length) {
          state.LegalTransporter.pop();
        }
      }

      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { physicalpeople });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.PhisycalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.PhisycalTransporter.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { legalpeople });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalTransporter.push(Infos);
          }
        }
      });
      Infos.last_page = payload.last_page;
      Infos.current_page = payload.current_page;
    },
    GET_ALL_TRANSPORTER_BY_ENTERPRISE: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;
      var indx = 0;
      let physicalpeople = {};
      let legalpeople = {};
      let auxPayload = { ...payload };
      delete auxPayload.data;

      if (payload.type === "PhysicalPeople") {
        while (state.PhisycalTransporter.length) {
          state.PhisycalTransporter.pop();
        }
      } else {
        while (state.LegalTransporter.length) {
          state.LegalTransporter.pop();
        }
      }

      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { physicalpeople });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.PhisycalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.PhisycalTransporter.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { legalpeople });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalTransporter.push(Infos);
          }
        }
      });
      Infos.last_page = payload.last_page;
      Infos.current_page = payload.current_page;
    },
    GET_AUTOCOMPLETE_TRANSPORTER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;
      var indx = 0;
      let physicalpeople = {};
      let legalpeople = {};
      let auxPayload = { ...payload };
      delete auxPayload.data;

      if (payload.type === "PhysicalPeople") {
        while (state.PhisycalTransporter.length) {
          state.PhisycalTransporter.pop();
        }
      } else {
        while (state.LegalTransporter.length) {
          state.LegalTransporter.pop();
        }
      }

      payload.data.forEach((e) => {
        if (e.type_people === "PhysicalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          physicalpeople = { ...e.people.physicalpeople };
          contacts = e.people.contact;
          address = e.people.address;
          banks = e.people.bank_data;

          Object.assign(Infos, { physicalpeople });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.PhisycalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.PhisycalTransporter.push(Infos);
          }
        } else if (e.type_people === "LegalPeople") {
          Infos = {};
          contacts = {};
          address = {};
          banks = {};
          rntr = e.rntr;
          security = e.security;

          legalpeople = { ...e.people.legalpeople };
          address = e.people.address;
          contacts = e.people.contact;
          banks = e.people.bank_data;

          Object.assign(Infos, { legalpeople });
          Object.assign(Infos, { contacts });
          Object.assign(Infos, { address });
          Object.assign(Infos, { banks });
          Object.assign(Infos, { security });
          Object.assign(Infos, { rntr });
          Infos.type_people = e.type_people;
          Infos.enterprise_id = e.enterprise_id;
          Infos.page = { ...auxPayload };
          Infos.id = e.id;

          indx = state.LegalTransporter.find((e) => e.id === Infos.id);
          if (indx === undefined) {
            state.LegalTransporter.push(Infos);
          }
        }
      });
      Infos.last_page = payload.last_page;
      Infos.current_page = payload.current_page;
    },

    ADD_PF_TRANSPORTER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;
      let physicalpeople;

      physicalpeople = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      rntr = payload.rntr;
      security = payload.security;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { security });
      Object.assign(Infos, { rntr });
      Object.assign(Infos, { physicalpeople });

      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.transporterToAdd = Infos
      state.PhisycalTransporter.push(Infos);
    },

    ADD_PJ_TRANSPORTER: (state, payload) => {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;
      let legalpeople = {};

      legalpeople = { ...payload.people.legalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      rntr = payload.rntr;
      security = payload.security;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { security });
      Object.assign(Infos, { rntr });
      Object.assign(Infos, { legalpeople });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.transporterToAdd = Infos
      state.LegalTransporter.push(Infos);
    },

    UPDATE_PF_TRANSPORTER(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;

      Infos = { ...payload.people.physicalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      rntr = payload.rntr;
      security = payload.security;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { security });
      Object.assign(Infos, { rntr });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;
      state.PhisycalTransporter.Update(Infos);

    },

    UPDATE_PJ_TRANSPORTER(state, payload) {
      let Infos = {};
      let contacts = {};
      let address = {};
      let banks = {};
      let rntr = 0;
      let security = 0;


      Infos = { ...payload.people.legalpeople };
      contacts = payload.people.contact;
      address = payload.people.address;
      banks = payload.people.bank_data;
      rntr = payload.rntr;
      security = payload.security;

      Object.assign(Infos, { contacts });
      Object.assign(Infos, { address });
      Object.assign(Infos, { banks });
      Object.assign(Infos, { security });
      Object.assign(Infos, { rntr });
      Infos.type_people = payload.type_people;
      Infos.enterprise_id = payload.enterprise_id;
      Infos.id = payload.id;

      state.isUpdated = true

      state.LegalTransporter.Update(Infos);
    },
    DESTROY_TRANSPORTER: (state, payload) => {
      let index = -1;
      if (payload.type_people === "PhysicalPeople") {
        let statePhisycal = state.PhisycalTransporter;
        index = state.PhisycalTransporter.findIndex(
          (value) => value.id === payload.id
        );
        if (index >= 0) {
          statePhisycal.splice(index, 1);
          state.PhisycalTransporter = statePhisycal;
        }
      } else if (payload.type_people === "LegalPeople") {
        let stateLegal = state.LegalTransporter;
        index = state.LegalTransporter.findIndex(
          (value) => value.id === payload.id
        );
        if (index >= 0) {
          stateLegal.splice(index, 1);
          state.LegalTransporter = stateLegal;
        }
      }
    },
  },
  actions: {
    ITEM_TO_UPDATE: function (context, payload) {
      
      context.commit(ITEM_TO_UPDATE, payload)
    },
    GET_ALL_TRANSPORTER_TO_SELECT_FIELD({ commit }, payload) {
      axios
        .post("transporter/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_TRANSPORTER_TO_SELECT_FIELD, response.data[0]);
        });
    },
    GET_ALL_TRANSPORTER({ commit }, payload) {
      axios
        .post("transporter/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
        })
        .then((response) => {
          response.data[0].type = payload.type_people;
          commit(GET_ALL_TRANSPORTER, response.data[0]);
        });
    },
    GET_ALL_TRANSPORTER_BY_ENTERPRISE({ commit }, payload) {
      axios
        .post("transporter/getAllByEnterprise", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          enterprise_id: payload.enterprise_id
        })
        .then((response) => {
            response.data[0].type = payload.type_people;
            commit(GET_ALL_TRANSPORTER_BY_ENTERPRISE, response.data[0]);
        });
    },
    GET_AUTOCOMPLETE_TRANSPORTER({ commit }, payload) {
      axios
        .post("transporter/autocomplete", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
          type_people: payload.type_people,
          search: payload.search,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
            response.data[0].type = payload.type_people;
            commit(GET_AUTOCOMPLETE_TRANSPORTER, response.data[0]);
        });
    },

    ADD_PF_TRANSPORTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("transporter/createphysicalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PF_TRANSPORTER, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Transportador cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    ADD_PJ_TRANSPORTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("transporter/createlegalPeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(ADD_PJ_TRANSPORTER, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Transportador cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_PF_TRANSPORTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("transporter/updatephysicalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PF_TRANSPORTER, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Transportador atualizado com sucesso!",
              type: "s",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },


    UPDATE_PJ_TRANSPORTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("transporter/updatelegalpeople", payload)
          .then((response) => {
            resolve();
            response.data[0].enterprise_id = payload.enterprise_id;
            commit(UPDATE_PJ_TRANSPORTER, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Transportador atualizado com sucesso!",
              type: "s",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_TRANSPORTER({ commit }, payload) {
      axios
        .post("transporter/delete", {
          id: payload.id,
          enterprise_id: payload.enterprise_id,
        })
        .then((response) => {
          response;
          commit(DESTROY_TRANSPORTER, payload);
          commit(SPLICE_ERRO, {
            alert: "Transportador deleteado com sucesso!",
            type: "S",
          });
        });
    },
  },

  getters: {
    getTransporterToAdd: (state) => state.transporterToAdd,
    getPhisycalTransporter: (state) => state.PhisycalTransporter,
    getLegalTransporter: (state) => state.LegalTransporter,
    getTransportPhysicalAuto: (state) => state.physicalAutocomplete,
    getTransportLegalAuto: (state) => state.legalAutocomplete,
    getItemToUpdateTransporter: (state) => state.itemToUpdate,
    getIsUpdatedTransporter: (state) => state.isUpdated,
    getSelectTransporter: (state) => state.selectTransporter,
  },
};

export default moduleSubGroup;
