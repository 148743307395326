
const ADD_PF_CLIENT = "ADD_PF_CLIENT";
const ADD_PJ_CLIENT = "ADD_PJ_CLIENT";
const UPDATE_PF_CLIENT = "UPDATE_PF_CLIENT";
const UPDATE_PJ_CLIENT = "UPDATE_PJ_CLIENT";
const GET_ALL_CLIENT = "GET_ALL_CLIENT";
const DESTROY_CLIENT = "DESTROY_CLIENT";
const GET_AUTOCOMPLETE_CLIENT = 'GET_AUTOCOMPLETE_CLIENT';
const GET_FILTER_CLIENT = 'GET_FILTER_CLIENT';
const ITEM_TO_UPDATE_CLIENT = "ITEM_TO_UPDATE_CLIENT";
const GET_ALL_CLIENT_TO_SELECT_FIELD = "GET_ALL_CLIENT_TO_SELECT_FIELD";
const GET_ALL_CLIENT_BY_ENTERPRISE = "GET_ALL_CLIENT_BY_ENTERPRISE";

module.exports = {
    ADD_PF_CLIENT,
    ADD_PJ_CLIENT,
    UPDATE_PF_CLIENT,
    UPDATE_PJ_CLIENT,
    GET_ALL_CLIENT,
    DESTROY_CLIENT,
    GET_AUTOCOMPLETE_CLIENT,
    GET_FILTER_CLIENT,
    ITEM_TO_UPDATE_CLIENT,
    GET_ALL_CLIENT_TO_SELECT_FIELD,
    GET_ALL_CLIENT_BY_ENTERPRISE,
    GET_FILTER_CLIENT
}
