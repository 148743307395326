<template>
  <div>
    <v-form v-model="component.accountingForm">
      <v-col>
        <v-select
          label="Centro de Custo"
          v-model="component.centerCostId"
          :readonly="component.justVisible"
          :items="this.costCenters"
          item-value="id"
          item-text="name_cost_center"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="*Plano de Contas"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialog"
          v-model="component.plan"
          :readonly="component.justVisible"
          :items="this.component.accounting_plan"
          return-object
          item-text="description"
          outlined
          :rules="[() => !!component.plan || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-form>
    <AccountPlans />
  </div>
</template>

<script>
import { mdiPlusCircle } from "@mdi/js";
import AccountPlans from "../../../account_plans/AddDialog/index.vue";
import { GET_ALL_COST_CENTER } from "../../../../store/actions/cost_center"
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object,
  },
  components: {
    AccountPlans,
  },
  methods: {
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterAccountPlan",true)
    },
    getAllCostCenter() {
      const payload = {
        page: 1,
        limit: null,
        paginate: false,
      }
      this.$store.dispatch(GET_ALL_COST_CENTER, payload)
    }
  },
  computed:{
    ...mapGetters(["getCostCenter"]),
  },
  data() {
    return {
      icons: [mdiPlusCircle],
      costCenters: Array()
    };
  },

  created() {
    this.getAllCostCenter()
  },

  watch: {
    getCostCenter: function(val) {
      this.costCenters = Object.values(val);
    }
  }
};
</script>

<style>
</style>