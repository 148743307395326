<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this" />
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="searchInput"
          append-icon="mdi-magnify"
          @keyup.enter="autocompleteBarCode()"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        v-if="permission.Visualizar"
        :getters="getBarcode"
        :destroy_barcode="destroy_barcode"
        :search="search"
        :headers="headers"
        :open_Dialog="open_Dialog"
      />
      <AddDialog v-if="addDialog" :component="this" />
    </v-card>
    <AlertApi />
    <loadScreen />
  </Container>
</template>


<script>
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/barcode/CardTitle/";
import Table from "../components/barcode/Table/";
import AddDialog from "../components/barcode/AddDialog/";
import AlertApi from "../components/alert_api/alert_api";
import loadScreen from "../components/load/";

import { mapGetters } from "vuex";
import UiBusinessRules from "../UIBusinessRule/BarCode/View/View"

export default {
  data: () => ({
    show: true,
    search: "",
    searchInput: "",
    code: "",
    product: [],
    filter: "",
    getter: "getBarcode",
    addDialog: false,
    objectToUpdate: {},
    title: "Código de Barra",
    headers: [
      { text: "Código do Produto", value: "product_id" },
      { text: "Descrição Produto", value: "products.description_item" },
      { text: "Código de barras", value: "bar_code" },
      { text: "Ações", value: "actions" },
    ],
    permission: Object(),
    enterprise_id: Number(),
  }),

  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    loadScreen,
    AlertApi,
  },

  methods: {
    ...UiBusinessRules
  },

  created() {
    this.GetAll();
    // this.GetAllNoPageProduct();
  },

  computed: {
    ...mapGetters(["getBarcode"]),
    ...mapGetters(["getProduct"]),
    ...mapGetters(["getCadastrosLinks"]),
  },

  watch: {
    getProduct: function (val) {
      this.product = Object.values(val);
    },
    searchInput: function (val) {
      if(val.length == 0) {
        this.GetAll();
        this.search = ""
      }
    },
    search: function (val) {
      if(val.length == 0) {
        this.GetAll();
      } else if(val.length >= 2){
        this.barcode_filter()
      }
    }
  },

  mounted() {
    // usando js para pegar o nome da rota, vai funcionar para todas rotas menos a rota /
    let name = document.URL.split("/").pop();
    this.permission = this.getCadastrosLinks.getPermissions(name);
    // usar getter especifico para comparar as rotas, getPermission deve receber ultimo parametro da rota
    // exemplo se a rota é http://localhost:8080/Aliquot voce deve passar para ele somente o Aliquot
  },
};
</script>


<style scoped>
</style>