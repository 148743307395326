<template>
  <v-container>
    <div>
      <v-form v-model="component.validGenerateDate" ref="form" :disabled="isDisable">
        <v-row>
          <v-col>
            <v-text-field
              label="*Data de Emissão"
              placeholder="null"
              type="Date"
              v-model="component.form.date_of_issue"
              outlined
              :rules="[
                () => !!component.form.date_of_issue || 'Campo Obrigatorio',
              ]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="*Tipo de Nota"
              v-model="component.form.type_grade_id"
              :items="getModeItens"
              item-text="description"
              item-value="id"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              outlined
            ></v-select>
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              label="*Finalidade da Nota"
              :items="finality_note"
              item-text="text"
              item-value="value"
              v-model="component.form.finality_nf"
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              outlined
              :items="taxOperationItens"
              item-text="description"
              item-value="id"
              label="*Tipo de Operação"
              append-outer-icon="mdi-plus-circle"
              @click:append-outer="showDialog('taxoperationtype')"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-model="component.form.tax_operation_types_id"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="*Nome/Razão Social"
              v-model="description"
              :items="clients_items"
              item-text="name"
              item-value="id"
              return-object
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              label="Número da Nota"
              disabled
              v-model="component.form.number_grade"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Serie"
              disabled
              v-model="component.form.series"
              outlined
            ></v-text-field> </v-col
        ></v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Chave de Acesso da NFE"
              v-model="component.form.access_key"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Prot. Autorização de uso"
              v-model="component.form.authorization_protocol"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              disabled
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Informações Adicionais"
              v-model="component.form.note"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <OperationTypeDialog />
    </div>
  </v-container>
</template>

<script>
import { GET_ALL_TAX_OPERATION_TYPE } from "../../../../store/actions/tax_operation_types";
import OperationTypeDialog from "../../../tax_operation_type/AddDialog/index.vue";
import { GET_ALL_NOTE_MODEL } from "../../../../store/actions/note_model";
import { GET_ALL_CLIENT_TO_SELECT_FIELD } from "../../../../store/actions/client";
import { mapGetters } from "vuex";

export default {
  components: {
    OperationTypeDialog,
  },
  props: {
    component: Object,
    client: Object,
    isDisable: Boolean,
  },

  data: () => ({
    addDialogClient: false,
    description: Object(),
    item_client: Array(),
    taxOperationItens: Array(),
    getModeItens: Array(),
    client_id: Object(),
    typeNote: Object(),
    clients_items: Array(),
    finality_note: [
      { text: "NFE-NORMAL", value: "1" },
      { text: "NFE-COMPLEMENTAR", value: "2" },
      { text: "NFE-AJUSTE", value: "3" },
      { text: "DEVOLUCAO PRODUTO", value: "4" },
    ],
  }),
  methods: {
    showDialog(dialog) {
      if (dialog == "taxoperationtype") {
        this.$store.dispatch("setShowDialogRegisterTaxOperationType", true);
      }
    },
    async GetAllOperationType() {
      await this.$store.dispatch(GET_ALL_TAX_OPERATION_TYPE, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    async GetAllClient() {
      await this.$store.dispatch(GET_ALL_CLIENT_TO_SELECT_FIELD, {
        page: null,
        limit: null,
        paginate: false,
      });
    },
    async GetAllNoteModel() {
      await this.$store.dispatch(GET_ALL_NOTE_MODEL, {
        page: 0,
        limit: null,
        paginate: false,
      });
    },
    name_client(value) {
      this.clients_items = value;
      this.clients_items.forEach((e) => {
        if (e.type_people === "LegalPeople") {
          e.name = e.people.legalpeople.fantasyName;
        } else {
          e.name = e.people.physicalpeople.name;
        }
      });
    },
  },
  watch: {
    getTaxOperationType: function (val) {
      if (this.taxOperationItens.length == 0) {
        this.taxOperationItens = Object.values(val);
      } 

      // this.taxOperationItens = Object.values(val).filter(
      //   (item) => item.type === "SAIDA"
      // );
    },
    getNoteModel: function (val) {
      this.getModeItens = Object.values(val);
    },
    getSelectClient: function (val) {
      this.name_client(val);
    },
    description: function (val) {
      this.component.form.client_id = val.id;
    },
  },
  computed: {
    ...mapGetters(["getNoteModel", "getTaxOperationType", "getSelectClient"]),
  },

  created() {
    this.GetAllOperationType();
    this.GetAllNoteModel();
    this.GetAllClient();
    this.description = { ...this.client };
  },
};
</script>

<style></style>
