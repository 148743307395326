<template>
  <v-container>
    <v-form ref="form" v-model="component.validBankAccount">
      <v-row>
        <v-col>
          <v-autocomplete
            :items="listBanks"
            item-text="description"
            label="Banco"
            v-model="component.banks.bank"
            :readonly="component.justVisible"
            outlined
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="component.banks.count"
            :readonly="component.justVisible"
            label="Conta"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="component.banks.agency"
            :readonly="component.justVisible"
            label="Agência"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
      <v-col cols="6">
        <v-autocomplete
           v-model="component.banks.key_pix"
           label="Tipo de chave pix"
           :items="pixKey"
           outlined
         >
      </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field
           v-model="component.banks.value_pix"
           label="Valor da chave pix"
           outlined
         >
      </v-text-field>
      </v-col>
     </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="component.banks.manager"
            :readonly="component.justVisible"
            label="Gerente"
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { GET_ALL_BANK } from "../../../../store/actions/bank";
import { mapGetters } from "vuex";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: Boolean(),
    listBanks: Array(),
    textRules: [(v) => !!v || "Campo obrigatório!"],
    pixKey: ["CPF ou CNPJ", "E-mail", "Número de telefone celular"],
  }),
  methods: {
    reset() {
      this.$refs.form.resetValidation();
    },
    async getAllBanks(page=1) {
      const payload = {
        page: page,
        limit: null,
        paginate: false,
      }
      await this.$store.dispatch(GET_ALL_BANK, payload);
    }
  },

  computed: {
    ...mapGetters(["getBank"])
  },

  created() {
    this.getAllBanks()
  },

  watch: {
    getBank: function(val) {
      this.listBanks = Object.values(val)
    }
  }
};
</script>

<style>
</style>