<template>
  <v-container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Contas a Pagar</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3" v-if="!companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              append-outer-icon="mdi-home-group"
              @click:append-outer="changeSelect()"

              >Adicionar</v-select
            >
          </v-col>
          <v-col class="d-flex" cols="12" sm="3" v-if="companyGroup">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Grupo de Empresa"
              item-value="id"
              item-text="name"
              loader-height
              v-model="selectCompanyGroup"
              :items="companyGroups"
              append-outer-icon="mdi-home"
              @click:append-outer="changeSelect()"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="validDate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon>Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
        <InvalidDateDialog :component="this" v-if="invalidDateDialog" />
      </v-container>
    </v-card>
    <AlertError></AlertError>
  </v-container>
</template>

<script>
import Form from "../../../components/report/bills_pay/Form/index";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GET_ALL_ACCOUNT_PLAN } from "../../../store/actions/account_plan";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../store/actions/companygroup";
import { GET_ALL_COST_CENTER } from "../../../store/actions/cost_center";
import { GET_REPORT_BILLSPAY } from "../../../store/actions/bills_pay"; 
import AlertError from "../../../components/alert_api/alert_api.vue";
import InvalidDateDialog from "@/views/Reports/bills_pay/invalidDateDialog";
import { mapGetters } from "vuex";

export default {
  components: { Form, InvalidDateDialog, AlertError },

  data: () => ({
    dialog: Boolean(),
    provider: Object,
    valid: false,
    userName: String(),
    selectValue: Object,
    enterprise: [],
    companyGroups: Array(),
    selectCompanyGroup: Object(),
    companyGroup: Boolean(),
    account: [],
    costCenters: [],
    invalidDateDialog: false,
    report: {
      provider_id: null,
      status: null,
      date_initial: null,
      date_final: null,
      type_title: null,
      account_plans_id: null,
      type_report: null,
      search_id: null,
      center_cost: null,
    },
  }),

  methods: {
    validDate() {
      const dateInitial = new Date(this.report.date_initial);
      const dateFinal = new Date(this.report.date_final);
      if (!this.report.date_initial || (dateFinal >= dateInitial)) {
        this.Generate();
      } else {
        this.invalidDateDialog = true;
      }
    },
    async Generate() {
      if(this.companyGroup) {
        this.report.type_report = "COMPANYGROUP"
        this.report.search_id = this.selectCompanyGroup.id
      } else {
        this.report.type_report = "ENTERPRISE"
        this.report.search_id = this.selectValue.id
      }
      let all_report = Object.assign({}, this.report);
      await this.$store.dispatch(GET_REPORT_BILLSPAY, all_report).then((response) => {
          this.dialog = !this.dialog;
          let arrayenterprise = [];
          let arrayaddress = [];
          let objeto = Object.assign({}, this.selectValue);
          let objetoadress = Object.assign({}, this.selectValue.address);
          arrayenterprise.push(objeto);
          arrayaddress.push(objetoadress);
          this.createPDF(response.data[0], arrayenterprise, arrayaddress);
        });
      },
    
    createPDF(items, enterprise, address) {
      let data = new Date();
      let totalValueTitle = 0;
      let totalValueNet = 0;
      let totalTitleBalance = 0;
      //let pdfName = "Relatório_Produtos";
      items.forEach((e) => {
        e.issuance_date =
        e.issuance_date != null
        ? e.issuance_date.split("-").reverse().join("/")
        : "";
        e.issuance_due =
        e.issuance_due != null
        ? e.issuance_due.split("-").reverse().join("/")
        : "";
        e.value_title =
        e.value_title != null
        ? `R$ ${e.value_title.toFixed(2).replace(".", ",")}`
        : "";
        e.value_net =
        e.value_net != null
        ? `R$ ${e.value_net.toFixed(2).replace(".", ",")}`
        : "";
        e.title_balance =
        e.title_balance != null
        ? `R$ ${e.title_balance.toFixed(2).replace(".", ",")}`
        : "";
        if (e.type_people == "LegalPeople") {
          e.name = e.fantasyName;
        }
        totalValueTitle += e.value_title
        ? parseFloat(e.value_title.replace("R$ ", "").replace(",", "."))
        : 0;
        totalValueNet += e.value_net
        ? parseFloat(e.value_net.replace("R$ ", "").replace(",", "."))
        : 0;
        totalTitleBalance += e.title_balance
        ? parseFloat(e.title_balance.replace("R$ ", "").replace(",", "."))
        : 0;
      });
      
      items.push({
        provider_id: { content: "Total", styles: { fontStyle: "bold" } },
        name: { content: "", styles: { fontStyle: "bold" } },
        issuance_date: { content: "", styles: { fontStyle: "bold" } },
        issuance_due: { content: "", styles: { fontStyle: "bold" } },
        number_title: { content: "", styles: { fontStyle: "bold" } },
        value_title: {
          content: `R$ ${totalValueTitle.toFixed(2).replace(".", ",")}`,
          styles: { fontStyle: "bold" },
        },
        value_net: {
          content: `R$ ${totalValueNet.toFixed(2).replace(".", ",")}`,
          styles: { fontStyle: "bold" },
        },
        title_balance: {
          content: `R$ ${totalTitleBalance.toFixed(2).replace(".", ",")}`,
          styles: { fontStyle: "bold" },
        },
      });
      
      var doc = new jsPDF("p", "pt");
      var columns = [
        { title: "Cód. Fornecedor",    dataKey: "provider_id" },
        { title: "Nome do Fornecedor", dataKey: "name" },
        { title: "Emissão",            dataKey: "issuance_date" },
        { title: "Vencimento",         dataKey: "issuance_due" },
        { title: "Plano de Contas",    dataKey: "account_plan_description"},
        { title: "Valor do Título",    dataKey: "value_title" },
        { title: "Valor Líquido",      dataKey: "value_net" },
        { title: "Saldo do Título",    dataKey: "title_balance" },
      ];
      doc.setLineWidth(1);
      doc.rect(20, 20, 555, 800);
      doc.line(40, 85, 555, 85);
      doc.line(40, 125, 555, 125);
      
      doc.setFontSize(7);
      doc.text(`Usuário : ${this.userName}`, 430, 40);
      doc.text(`${data.toLocaleString()}`, 430, 50);

      doc.setFontSize(15);
      
      if(this.companyGroup) {
        doc.text("RELATÓRIO DE CONTAS A PAGAR POR GRUPO DE EMPRESA", 80, 70);
        doc.setFontSize(12);
        doc.text(`${this.selectCompanyGroup.name}`, 240, 110);
      } else {
        doc.text("RELATÓRIO DE CONTAS A PAGAR POR EMPRESA", 120, 70);
        doc.setFontSize(7);
        doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
        doc.text(`Razão Social : ${enterprise[0].name}`, 140, 100);
        doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 430, 100);
        doc.text(`Rua : ${address[0].street}`, 40, 115);
        doc.text(`Numero : ${address[0].number}`, 200, 115);
        doc.text(`Cidade : ${address[0].city}`, 255, 115);
        doc.text(`Estado : ${address[0].state}`, 400, 115);
        doc.text(`CEP : ${address[0].cep}`, 500, 115);
      }
      this.$refs.form.reset();
      this.$refs.form.validate();
      this.$refs.form.resetValidation();
      
      doc.autoTable(columns, items, {
        columnStyles: {
          //
        },
        headStyles: {
          fillColor: 220,
          textColor: 0,
        },
        startY: 140,
        theme: "grid",
        styles: {
          overflow: "linebreak",
          fontSize: 7,
          cellWidth: "wrap",
          halign: "center",
          valign: "middle",
        },
      });
      const pages = doc.internal.getNumberOfPages();
      // const pageWidth = doc.internal.pageSize.width;  //Optional
      // const pageHeight = doc.internal.pageSize.height;  //Optional
      //console.log(pageWidth , pageHeight);
      doc.setFontSize(8); //Optional
      for (let j = 1; j < pages + 1; j++) {
        // let horizontalPos = pageWidth / 2;  //Can be fixed number
        // let verticalPos = pageHeight - 10;  //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} / ${pages}`, 550, 40);
      }
      doc.output("dataurlnewwindow");
      //doc.save(pdfName + ".pdf");
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
      await this.$store.dispatch(GET_ALL_ACCOUNT_PLAN, value);
      await this.$store.dispatch(GET_ALL_COMPANY_GROUP, value);
    },

    getAllCostCenter() {
      const payload = {
        page: 1,
        limit: null,
        paginate: false,
      }
      this.$store.dispatch(GET_ALL_COST_CENTER, payload)
    },

    changeSelect() {
      this.companyGroup = !this.companyGroup
    }
  },

  computed: {
    ...mapGetters(["getEnterprises", "getAccount_Plan", "getCompanygroup", "getCostCenter"]),
    ...mapGetters({ userLogin: "auth/userLogin" }),
  },

  created() {
    this.GetAllNoPage();
    this.getAllCostCenter();
    this.userName = this.userLogin.user.name;
  },

  watch: {
    getCompanygroup(val) {
      this.companyGroups = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.companyGroups[0]);
      this.selectCompanyGroup = aux
    },

    getEnterprises(val) {
      this.enterprise = Object.keys(val).map((key) => val[key]);
      let aux = Object.assign([], this.enterprise[0]);
      this.selectValue = aux;
    },

    getAccount_Plan(val) {
      this.account = Object.keys(val).map((key) => val[key]);
    },

    getCostCenter(val) {
      this.costCenters = Object.keys(val).map((key) => val[key]);
      
    }
  },
};
</script>

<style>
</style>