const cadastros = [{
  "text": "Aliquota de ICMS",
  "icon": "mdi-sack-percent",
  "route": "/Aliquot",
  "class": "white--text",
  "permission_col": [],
  "active": false
},
{
  "text": "PIS/COFINS",
  "icon": "mdi-alpha-p-box",
  "route": "/PisCofins",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Banco",
  "icon": "mdi-bank-outline",
  "route": "/Bank",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Caixas",
  "icon": "mdi-cash-register",
  "route": "/Cashier",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "CFOP",
  "icon": "mdi-alpha-c-box",
  "route": "/CFOP",
  "class": "white--text",
  "permission_col": [],
  "active": false
},
{
  "text": "CSOSN",
  "icon": "mdi-alpha-c-circle",
  "route": "/CSON",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Cst",
  "icon": "mdi-alpha-c-circle-outline",
  "route": "/CST",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Grupo de produto",
  "icon": "mdi-checkbox-multiple-blank",
  "route": "/productgroup",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "NCM",
  "icon": "mdi-alpha-n-box",
  "route": "/NCM",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Código de barras",
  "icon": "mdi-barcode",
  "route": "/Barcode",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Unidade de medida",
  "icon": "mdi-tape-measure",
  "route": "/unit",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Forma de pagamento",
  "icon": "mdi-credit-card",
  "route": "/formofpayment",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Condição de pagamento",
  "icon": "mdi-credit-card-multiple",
  "route": "/paymentterm",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Grupo de empresa",
  "icon": "mdi-home-city",
  "route": "/companygroup",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Administradoras de cartão",
  "icon": "mdi-card-account-details-star",
  "route": "/Card_adm",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Plano de Contas",
  "icon": "mdi-file-account",
  "route": "/Account_plans",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Modelo de Notas",
  "icon": "mdi-file-document-multiple",
  "route": "/note_models",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Produto",
  "icon": "mdi-basket",
  "route": "/product",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Cliente",
  "icon": "mdi-account-group",
  "route": "/client",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Empresa",
  "icon": "mdi-office-building",
  "route": "/enterprise",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Fornecedor",
  "icon": "mdi-briefcase-plus",
  "route": "/provider",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Profissão",
  "icon": "mdi-account-tie",
  "route": "/profession",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Sub Grupo",
  "icon": "mdi-account-supervisor-circle",
  "route": "/subproductgroup",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Transportador",
  "icon": "mdi-truck-fast",
  "route": "/transporter",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Vendedor",
  "icon": "mdi-account-star",
  "route": "/seller",
  "class": "white--text",
  "permission_col": [],
  "active": true
}, {
  "text": "Tipo de Comissão",
  "icon": "mdi-account-cash",
  "route": "/commissiontype",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Tipo de Operação de caixa",
  "icon": "mdi-cash-marker",
  "route": "/cashieroperation",
  "class": "white--text",
  "permission_col": [],
  "active": false
}, {
  "text": "Tipo de Operação Fiscal",
  "icon": "mdi-cog-box",
  "route": "/taxoperationtype",
  "class": "white--text",
  "permission_col": [],
  "active": false
},
{
  "text": "PDV",
  "icon": "mdi-domain",
  "route": "/PDV",
  "class": "white--text",
  "permission_col": [],
  "active": false
},
{
  "text": "Centro de Custo",
  "icon": "mdi-cash-marker",
  "route": "/costcenter",
  "class": "white--text",
  "permission_col": [],
  "active": true
}]

export {
  cadastros
};