import apifiscal from '../apifiscal';

const {
    GET_ALL_XML_NFCE,
} = require("./actions/write_off_xml")

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleWrite_off_xml = {
    state: {
        listXML: Object(),
    },
    getters: {
        getListXML: function (state) {
            return state.listXML
        }
    },
    mutations: {
        GET_ALL_XML_NFCE: function (state, payload) {
            state.listXML = payload
        }
    },
    actions: {
        async GET_ALL_XML_NFCE({ commit }, payload) {
            let token= localStorage.getItem("token_fiscal");
            return new Promise((resolve,reject) => {
                apifiscal.post('/noteissuenfce/downloadXmlNfce', payload, {
                headers: {'Authorization': 'Bearer '+ token}
              }).then(( response) => {
                resolve({ response })
                commit(GET_ALL_XML_NFCE, response);
              }).catch((error)=>{
                commit(SPLICE_ERRO, {
                    alert: "Error ao processar download de XML",
                    type: "E",
                  });
                reject({error})
              })
      
            })
        },
    },
};



export default moduleWrite_off_xml



     
  