let dataValuesSalesWithFormOfPayments = {
    series: [],
    chartOptions: {
        title: {
            text: 'Vendas por Forma de Pagamento',
            align: 'center',
            floating: false
        },
        chart: {
            type: 'pie',
            height: 300,
            width: 300,
        },
        labels: [],

        responsive: [{
            //breakpoint: 480,
            options: {
                chart: {
                    //width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    },
}
let modelValuesSalesWithFormOfPaymentsModel = {
    series: [],
    chartOptions: {
        chart: {
            type: 'pie',
            width: 300,
            height: 300,
        },
    },

}


export {
    dataValuesSalesWithFormOfPayments,
    modelValuesSalesWithFormOfPaymentsModel
}