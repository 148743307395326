<template>
    <v-row justify="center">
      <v-dialog
        persistent
        v-model="component.registerDialog"
        max-width="600"
      >
        <v-card>
          <v-card-title primary-title>Centro de Custo</v-card-title>

          <v-container>
            <v-divider></v-divider>
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              class="mt-6"
            >
              <v-col>
                <v-text-field
                  label="*Código"
                  color="success"
                  v-model="form.code"
                  :rules="[() => !!form.code || 'Campo Obrigatório']"
                  outlined
                  :readonly="component.justView"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="*Descrição"
                  color="success"
                  v-model="form.name_cost_center"
                  :rules="[() => !!form.name_cost_center || 'Campo Obrigatório']"
                  outlined
                  :readonly="component.justView"
                  required
                ></v-text-field>
              </v-col>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" @click="closeDialog()">
                  <v-icon class="mr-1">mdi-cancel</v-icon>Cancelar</v-btn>
                <v-btn
                  v-if="!component.justView"
                  color="success"
                  class="mr-4"
                  :disabled="!valid"
                  @click="
                    () => {
                      submit();
                    }
                  "
                >
                  <v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>
  export default {
    props: {
      component: Object,
    },
    data() {
      return {
        valid: true,
        form: {
            code: String(),
            name_cost_center: String(),
        }
      };
    },
  
    methods: {
        submit() {
          if(this.component.id == null) {
            this.component.create(this.form)
            this.$refs.form.reset();
          } else {
            this.component.update(this.form)
            this.$refs.form.reset();
          }
        },

        closeDialog() {
          this.$refs.form.reset();
          this.component.setRegisterDialog()
        }
      },

      created() {
        if(this.component.id != null) {
          this.form = this.component.costCenterToUpdate
        }
      },
  
  };
  </script>
  