let dataSalesPerStore = {
    series: [{
        data: []
    }],
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250
        },
        plotOptions: {
            bar: {
                barHeight: '45%',
                distributed: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#4EA03E', '#F0712C', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        dataLabels: {
            enabled: false,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            offsetX: 0,
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: [],
            labels: {
                show: false
            },
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        title: {
            text: 'Valor Total de Vendas',
            align: 'center',
            floating: true
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return ''
                    }
                }
            }
        }
    },
}

let modelSalesPerStore = {
    chartOptions: {
        chart: {
            type: 'bar',
            height: 250
        },

    },
}
export { dataSalesPerStore, modelSalesPerStore }