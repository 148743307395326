<template>
  <Container>
    <v-card>
      <v-card-text
        ><CardTitle :downTitle="changeDialog" :title="Title" :component="this"
      /></v-card-text>
      <v-layout>
        <Info :component="this" />
      </v-layout>
      <AddDialog
        :dialog="dialog"
        :changeDialog="changeDialog"
        :submit="submit"
      />
      <v-card-text>
        <v-text-field
          outlined
          label="Pesquisar"
          append-icon="mdi-magnify"
          v-model="searchInput"
          @keyup.enter="setAutocompleteValue()"
        ></v-text-field>
      </v-card-text>
      <Table :component="this" :header="header" :items="table_downtitle" />
    </v-card>
    <AlertAPI />
    <LoadScreen />
  </Container>
</template>

<script>
import AlertAPI from "../components/alert_api/alert_api";
import Container from "../components/Utils/Container/Container";
import CardTitle from "../components/TitleDown/CardTitle/";
import Info from "../components/TitleDown/Info/";
import Table from "../components/TitleDown/Table/";
import AddDialog from "../components/TitleDown/AddDialog/";
import LoadScreen from "../components/load/index.vue";
import {
  GET_BILLS_PAY_BY_STATUS,
  DOWNLOAD_BILLSPAY_MULTIPLE,
  AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS,
  GET_ALL_BILLS_PAY_BY_DATE,
} from "../store/actions/bills_pay";
import { GET_ALL_CASHIER } from "../store/actions/cashier";
import { mapGetters } from "vuex";
export default {
  components: {
    Container,
    CardTitle,
    Info,
    Table,
    AddDialog,
    AlertAPI,
    LoadScreen,
  },
  data: () => ({
    Title: "Baixa de Títulos a Pagar",
    valid: Boolean(),
    cashier: [],
    id_cashier: Number(),
    date: {
      date_initial: String(),
      date_final: String(),
    },
    current: Number(),
    total_table: Number(),
    total: Number(),
    dialog: Boolean(),
    enterpriseId: Number(),
    table_downtitle: Array(),
    downtitle: Array(),
    search: String(),
    searchInput: String(),
    header: [
      { text: "Status", value: "status" },
      { text: "Tipo do Título", value: "type_title" },
      { text: "Fornecedor", value: "provider.name" },
      { text: "Data de Emissão", value: "issuance_date" },
      { text: "Data de Vencimento", value: "issuance_due" },
      { text: "Número do Título", value: "number_title" },
      { text: "Valor do Título", value: "value_title" },
      { text: "Valor Líquido", value: "title_balance" },
      { text: "Baixar?", value: "down", align: "center" },
    ],
    selectedItems: Array(),
    downTitleValid: true,
    buttonLabel: "Baixar",
    dateSearch: Boolean(),
    date_low: String(),
  }),
  computed: {
    ...mapGetters(["getBillPays", "getCashier", "getAutoCompleteBillsToPay", "getBillPaysByStatus", "getDownloadCompleted"]),
  },
  methods: {
    setAutocompleteValue() {
        this.search = this.searchInput
      },

    async autoCompleteBillsPay(page = 1) {
        let payload = {
          search: this.search,
          page: page,
          limit: 10,
          paginate: true,
          enterprise_id: this.enterpriseId,
          status: 'Aberto',
        };
        await this.$store.dispatch(AUTO_COMPLETE_BILLS_TO_PAY_BY_STATUS, payload);
    },

    async GetBills_Pay(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
        status: "Aberto",
      };
      await this.$store.dispatch(GET_BILLS_PAY_BY_STATUS, value);
    },

    async GetAllNoPage() {
      let value = {
        page: null,
        limit: null,
        paginate: false,
      };
      await this.$store.dispatch(GET_ALL_CASHIER, value);
    },

    async submit() {

      try {
        for(let i = 0; i< this.selectedItems.length; i++) {
            this.downtitle.push({
              billstoPay_id: Number(),
              account_plans_id: Number(),
              title_balance: Number(),
              enterprise_id: Number(),
              issuance_due: String(),
              annotation: String(),
              description_movement: String(),
              number_movement: String(),
              date_movement: String(),
              id_cashier: Number(),
            })
            this.downtitle[i].billstoPay_id = this.selectedItems[i].id;
            this.downtitle[i].id_cashier = this.id_cashier;
            this.downtitle[i].account_plans_id = this.selectedItems[i].account_plans_id;
            this.downtitle[i].title_balance = this.selectedItems[i].title_balance
            this.downtitle[i].enterprise_id = this.selectedItems[i].enterprise_id;
            this.downtitle[i].issuance_due = this.selectedItems[i].issuance_due;
            this.downtitle[i].annotation = this.selectedItems[i].annotation;
            this.downtitle[i].description_movement = "Título de N°" + this.selectedItems[i].number_title;
            this.downtitle[i].type_movement = "Saida";
            this.downtitle[i].value_movement = this.selectedItems[i].title_balance;
            this.downtitle[i].number_movement = this.selectedItems[i].number_title;
            this.downtitle[i].date_movement = this.date_low;
            this.downtitle[i].balance_movement = this.selectedItems[i].title_balance
  
        }
        const payload = {
          download_title: this.downtitle
        }
        this.$store.dispatch(DOWNLOAD_BILLSPAY_MULTIPLE, payload);
      } catch (error) {
        alert(error);
      } finally {
        this.selectedItems = []
        this.downtitle = []
        this.total = 0
        this.changeDialog()
        this.GetBills_Pay()
      }
    },
    changeDialog() {
      this.dialog = !this.dialog;
    },
    find(page = 1) {
      this.dateSearch = true
      let payload = {
        page: page,
        limit: 10,
        paginate: true,
        initial_date: this.date.date_initial,
        final_date: this.date.date_final,
        enterprise_id: this.enterpriseId,
        status: 'Aberto',

      };
      this.$store.dispatch(GET_ALL_BILLS_PAY_BY_DATE, payload);
    },

    cancelFind() {
      this.dateSearch = false
      this.current_Date()
      this.GetBills_Pay()
    },

    current_Date() {
      var dNow = new Date();
      var month = dNow.getMonth() + 1;
      var dec, dFull;

      if (month < 10) {
        month = "0" + month;
      }

      if (dNow.getDate() <= 9) {
        dec = "0";

        dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
        this.date.date_initial = dFull;
        this.date.date_final = dFull;
      } else {
        dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
        this.date.date_initial = dFull;
        this.date.date_final = dFull;
      }
    },
  },
  watch: {
    "getAutoCompleteBillsToPay": function(val){
      this.table_downtitle = val.data
    },
    search: function(val) {
      if(val.length >=2) {
        this.autoCompleteBillsPay();
      } else {
        this.GetBills_Pay()
      }
    },
    searchInput: function (val) {
        if(val.length == 0) {
          this.GetBills_Pay()
        }
      },
    getBillPaysByStatus:function(val) {
      this.total_table = val.last_page;
      this.current = val.current_page;
      this.table_downtitle = val.data;
      
    },
    table_downtitle: function(val) {
      this.total = 0;
        val.forEach((e) => {
          if (e.down === true) {
            this.total += e.title_balance;
          }
          if(e.provider.type_people == "PhysicalPeople") {
            e.provider.name = e.provider.people.physicalpeople.name
          } else {
            e.provider.name = e.provider.people.legalpeople.fantasyName
          }
        });
      },
    

    current: function (val) {
      this.selectedItems = []
      if(this.searchInput.length >=2) {
        this.autoCompleteBillsPay(val);
      } else if(this.dateSearch){
        this.find(val)
      } else {
        this.GetBills_Pay && this.GetBills_Pay(val);
      }
    },

    getCashier: function (val) {
      this.cashier = Object.keys(val).map((key) => val[key]);
    },

    total: function (val) {
      if(this.total == 0 || this.id_cashier == 0 || this.date_low == '') {
        this.downTitleValid = true
      } else {
        this.downTitleValid = false
      }
    },

    id_cashier: function (val) {
      if(this.total == 0 || this.id_cashier == 0 || this.date_low == '') {
        this.downTitleValid = true
      } else {
        this.downTitleValid = false
      }
    },

    date_low: function (val) {
      if(this.total == 0 || this.id_cashier == 0 || this.date_low == '') {
        this.downTitleValid = true
      } else {
        this.downTitleValid = false
      }
    },

    getDownloadCompleted: function(val) {
      this.GetBills_Pay()
    },
  },
  created() {
    this.GetAllNoPage();
    this.GetBills_Pay();
    this.current_Date();
  },
};
</script>

<style>
</style>